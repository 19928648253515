import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import SideBar from '../SideBar/sidebar';
import API from '../../Services/BaseService';
import Loading from '../../Component/Loading/Loading';
import Pagination from 'reactjs-hooks-pagination';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import ls from 'local-storage';
import moment from 'moment';


const AssignCustomer = () => {
    const navigate = useNavigate();
    const userData = useSelector(state => state.LoginReducer.payload);
    const [PageNn, setPageNo] = useState("");

    const pageLimit = PageNn;

    const [customersInfo, setCustomersInfo] = useState("");
    const [loading, setLoading] = useState(true)
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [operatorId, setOperatorId] = useState("");
    const [selectedOperatorName, setSelectedOperatorName] = useState("");
    const [operatorName, setOperatorName] = useState("");
    const [assignDate, setAssignDate] = useState("");
    const [assignDateError, setAssignDateError] = useState("");
    const [operatorNameError, setOperatorNameError] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [fromdate, setfromdate] = useState("");
    const [todate, settodate] = useState("");
    const [fromDateError, setFromDateError] = useState("");
    const [search, setSearch] = useState("");
    const [CustError, setCustError] = useState("");
    const [newSelect, setNewSelect] = useState([]);

    let onSelectedData = [];

    console.log("newSelect", newSelect);


    const [popupTitle, setPopupTitle] = useState("");
    const [popupMsg, setPopupMsg] = useState("");
    const [popupType, setPopupType] = useState("");
    const [popupActionType, setPopupActionType] = useState("");
    const [popupActionValue, setPopupActionValue] = useState("");
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    const [popupActionPath, setPopupActionPath] = useState("");
    const [, setSelectAll] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);
    const [ Assignstatus , setAssignstatus] = useState([]);
    // const [, setflag] = useState(false);
    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };
    function formatMobileNO(value) {
        var x = value.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
        // console.log("value of x", x);
        value = '+1 ' + '(' + x[1] + ') ' + x[2] + '-' + x[3];
        // console.log("mobileno", value);
        return value;
    }

    // useEffect(() => {
    //     setCustomersInfo(customersInfo);
    // }, [customersInfo])

    useEffect(() => {
        CustomersListInfo();
        handleOperatorName();
         AsssignStatus();
    }, [])




    const AsssignStatus = () => {
       
        API.post("AssingmapCustomerlists/condition").then(response => {
            if (response.data.success == true) {
              // console.log("assignstatus",response); 
                         
               setAssignstatus(response.data?.data);
            }

        });
    }

    const CustomersListInfo = () => {
       
        API.post("mapCustomerlist/condition").then(response => {
            if (response.data.success == true) {
                
                setCustomersInfo(response.data?.data.map((data) => { return { ...data, isChecked: false, currentPage: "" } }));
                setTotalRecords(response.data.data?.length);
                document.getElementById("page").value = "10";
                setPageNo(10);
                console.log("check the selected data", PageNn);
                // setSelectedData()
                response.data?.data.map((data, index) => {
                    const touggle = document.getElementById(`touggle${index}`)
                    if (touggle != null) {
                        if (data.status === "Active") {
                            touggle.checked = true;
                        }
                        else {
                            touggle.checked = false;
                        }
                    }
                }
                
                )
                setLoading(false);
                setCurrentPage(1);
                // console.log("res", response.data.data);
            
            }

        });
    }
    console.log("customersInfo", customersInfo);

    const [page, setPage] = useState("")
    const [selectedPage, setSelectedPage] = useState(false);
    const [select, setSelect] = useState(false);
    const [len, setLen] = useState("")
    const [unCheckLen, setunCheckLen] = useState("")
    const [checkLen, setCheckLen] = useState("")
    const [flag, setFlag] = useState(false)

    useEffect(() => {

        if (currentPage === page && selectedPage === 1) {
            setSelectAll(true)
            setSelect(true)
        }
        else if (currentPage === page) {
            setSelect(true)
        }

        else {
            setSelectAll(false)
            setSelect(false)
        }



    }, [currentPage])

    useEffect(() => {
        if (!flag) {
            setSelect(false)
            console.log("useeffect first if calling");
        } else {
            if ((len !== unCheckLen) && (len !== checkLen)) {
                console.log("useeffect status", len !== unCheckLen);
                setSelect(false)
            } else if (checkLen === len) {
                setSelect(true)
                // setFlag(true)
                console.log("useeffect else if calling");
            } else {
                setSelect(false)
                console.log("useeffect else calling");
            }
        }

    }, [unCheckLen, checkLen])
    console.log("checklen", len, "uncheck", unCheckLen, checkLen, "checkLen");

    const handleSelectField = (item, id, flag, crntPage) => {
        console.log("newSelect", newSelect);

        setSelect(!select)
        setFlag(flag)
        onSelectedData.map((data) => { data.customer_id })
        // console.log("checkitem", id);
        const checkbox = document.getElementById(id)
        const filter = newSelect.filter(data => data.crntPage === crntPage);
        setNewSelect(filter.length ? newSelect.filter(item => item.crntPage !== crntPage) : [...newSelect, { crntPage }]);

        if (checkbox != null) {

            if (checkbox.checked === true) {
                console.log("if calling");
                const checkPage = checkbox.checked === true ? 1 : ""
                const checkPage1 = currentPage
                setSelectedPage(checkPage)
                setPage(checkPage1)
                console.log("checkitem checkPage", onSelectedData.map((data) => { return { "customer_id": data.customer_id } }));
                setSelectAll(true)
                setSelect(true)

                const selValues = [...selectedValues, ...onSelectedData.map((data) => { return { "customer_id": data.customer_id } })]
                const filteredArr = selValues.reduce((acc, current) => {
                    const x = acc.find(item => item.customer_id === current.customer_id);
                    if (!x) {
                        return acc.concat([current]);
                    } else {
                        return acc;
                    }
                }, []);
                setSelectedValues(filteredArr)

                for (let i = 0; i < onSelectedData.length; i++) {
                    console.log("findindex.len", onSelectedData.length);
                    setLen(onSelectedData.length);
                    console.log("findindex i", i);
                    const sorting = customersInfo.sort((a, b) => b.customer_id - a.customer_id);
                    const findIndex = sorting.findIndex(item => item.customer_id === onSelectedData[i].customer_id);
                    console.log("findindex", findIndex, sorting);
                    sorting[findIndex].isChecked = true;
                    sorting[findIndex].currentPage = crntPage;
                    setCustomersInfo(sorting);
                }

                // customersInfo.sort((a, b) => b.customer_id - a.customer_id) 
                // setCustomersInfo(customersInfo.map((data) => { return { ...data, isChecked: true } }))
                // data.customer_id
                //setflag(true);
            }
            else {
                console.log("else calling");
                const tem = selectedValues;
                //console.log("elseif calling");
                setSelectAll(false)
                for (let i = 0; i < onSelectedData.length; i++) {
                    for (let j = 0; j < tem.length; j++) {
                        if (tem[j].customer_id === onSelectedData[i].customer_id) {
                            tem.splice(j, 1);
                        }

                    }
                }

                // setCustomersInfo(customersInfo.map((data) => { return { ...data, isChecked: false } }))
                for (let i = 0; i < onSelectedData.length; i++) {
                    console.log("findindex.len", onSelectedData.length);
                    setLen(onSelectedData.length);
                    console.log("findindex i", i);
                    const sorting = customersInfo.sort((a, b) => b.customer_id - a.customer_id);
                    const findIndex = sorting.findIndex(item => item.currentPage === onSelectedData[i].currentPage);
                    console.log("findindex", findIndex, sorting);
                    sorting[findIndex].isChecked = false;
                    sorting[findIndex].currentPage = '';
                    setCustomersInfo(sorting);
                }
                // setflag(true);
            }
        }
    };

    const handleSelectChangeField = (items, crntPage) => {
        console.log("items", items);
        console.log("check the current pages", onSelectedData);

        const uncheckstatus = selectedValues.filter(data => data.customer_id !== items.customer_id);
        console.log("customersInfolenght", uncheckstatus.length, customersInfo.length);
        setunCheckLen(uncheckstatus.length)

        if (checkLen !== onSelectedData.length) {
            document.getElementById("SelectAll").checked = false;
            setSelect(false)
            console.log("single uncheck", uncheckstatus.length, onSelectedData.length, "checkLen", checkLen);
        }

        const checkval = selectedValues.filter((data) => data.customer_id === items.customer_id);
        if (checkval.length > 0) {
            console.log("if check calling");
            const remove = customersInfo.filter(data => data.customer_id === items.customer_id);
            setNewSelect(newSelect.filter(item => item.crntPage !== remove[0].currentPage));

            const allList = customersInfo;
            console.log("selectedValues", selectedValues);
            const findIndex = allList.findIndex((data) => data.customer_id === items.customer_id);
            allList[findIndex].isChecked = false;
            allList[findIndex].currentPage = '';
            console.log("findIndex if", findIndex);
            setCustomersInfo(allList);
            setSelectedValues(selectedValues.filter(data => data.customer_id !== items.customer_id));
            const value = selectedValues.filter(data => data.customer_id !== items.customer_id).length;
            setCheckLen(value.length);
            console.log("single selectedValues if", selectedValues.filter(data => data.customer_id !== items.customer_id).length);

        }
        else {

            console.log("else check calling");
            console.log("selectedValues", selectedValues);
            const allList = customersInfo;
            const findIndex = allList.findIndex((data) => data.customer_id === items.customer_id);
            // console.log("findIndex else",findIndex);
            allList[findIndex].isChecked = true;
            allList[findIndex].currentPage = crntPage;
            setCustomersInfo(allList);
            setSelectedValues([...selectedValues, { "customer_id": items.customer_id }])
            const value = [...selectedValues, { "customer_id": items.customer_id }]
            setCheckLen(value.length);
            console.log("single selectedValues last", value.length);

            const sellectAllElement = onSelectedData.filter(data => data.isChecked === true)
            if (sellectAllElement.length === onSelectedData.length && crntPage === currentPage) {
                setNewSelect([...newSelect, { crntPage }]);
            }
            console.log("element", sellectAllElement.length === onSelectedData.length && crntPage === currentPage);

        }


    };

    //add

    const getSelectedReport = () => {
        setCustError("");
        setAssignDateError("");
        setOperatorNameError("");

        if (selectedValues.length === 0) {
            setCustError("Customer Name is required");
            return;
        }
        if (!operatorId) {
            setOperatorNameError("Operator Name is required");
            return;
        }
        if (!assignDate) {
            setAssignDateError("Assign Date is required");
            return;
        }
        let request = {
            customerid: selectedValues.map(data => data.customer_id),
            operator_id: selectedOperatorName.operator_id,
            assign_date: assignDate,
            comments: "",
            assign_time: "",
            active: 1,
            createdBy: userData.admin_id,
            customer_map_status: "ADMIN",
            updatedBy: userData.admin_id
        };
        console.log("check the request", request);


        API.post('customerMapAddlist/add ', request).then((response) => {
            if (response.data.success == true) {
                togglePopup();
                setPopupTitle("Assign Customer");
                setPopupMsg("Assigned Customer Succesfully");
                setPopupType("success");
                setPopupActionType("redirect");
                setPopupActionValue("ok");
                setPopupActionPath("/customersMap");
            }
            else {
                togglePopup();
                setPopupTitle("Assign Customer");
                setPopupMsg(response.data.error.err);
                setPopupType("error");
                setPopupActionType("close");
                setPopupActionValue("close");
            }
        });
    };

    const redirection = (id) => {

        navigate({ pathname: "/CustomerView" });
        ls.set("AdminId", id);
        console.log("AdminId", id);
    };
    const handleOperatorName = () => {

        API.post("operatorLists/condition").then(response => {
            setOperatorName(response.data.data);
        });
    }

    const handleOperatorNameId = (e) => {
        setOperatorId(e.target.value);
        const selectedLangDetails = operatorName.filter(data => data.operator_id == e.target.value)[0]
        setSelectedOperatorName(selectedLangDetails);
    }

    const disablePastDt = current => {
        return current.isAfter(fromdate);
    };


    const handleclear = () => {
        setCustomerName("");
        setEmail("");
        setPhoneNo("");
        setfromdate("");
        settodate("");
        setFromDateError("");
        setSelectedOperatorName("");
        setOperatorId("");
        setOperatorName("");
        handleOperatorName();
        setAssignDate("");
        setLoading(true);
        CustomersListInfo();
        document.getElementById("page").value = "10";
        // document.getElementById("userName").value = "Select userName";
        setSelectedValues([])
       
            setNewSelect([]);
        for (let i = 0; i < customersInfo.length; i++) {
            const sorting = customersInfo;
            sorting[i].isChecked = false;
            sorting[i].currentPage = '';
            setCustomersInfo(sorting);
        }

    }

    const onKeydownAccountSearch = (event) => {
        if (event.key === "Enter") {
            CustomerSearch();
        }
    };

    const onSearch = (e) => {
        setSearch(e.target.value);
    }

    const CustomerSearch = () => {
        let request = {
            data: search
        }

        API.post("mapCustomerlistsearch/condition", request).then(response => {
            setCustomersInfo(response.data?.data.map((data) => { return { ...data, isChecked: false, currentPage: "" } }));
            setTotalRecords(response.data.data?.length);

            setCurrentPage(1);
        });
    }

    useEffect(() => {
        if (search != "") {
            const timer = setTimeout(() => {
                CustomerSearch();
            }, 200);
            return () => {
                clearTimeout(timer);
            };
        } else {
            setLoading(true);
            CustomersListInfo();
        }
    }, [search]);


    const inputProps = {
        placeholder: "From Date",
        value: fromdate
    };
    const inputProps1 = {
        placeholder: "To Date",
        value: todate
    };

    const customerFilter = () => {

        setFromDateError("")

        if (fromdate) {
            if (!todate) {
                setFromDateError("To Date is required")
                return;
            }

        }
        setLoading(true)
        let request = {
            customer_name: customerName,
            email: email,
            phone_no: phoneNo,
            from_date: fromdate,
            to_date: todate
        }

        API.post("mapCustomersearch/condition", request).then(response => {
            setCustomersInfo(response.data?.data.map((data) => { return { ...data, isChecked: false, currentPage: "" } }));
            setTotalRecords(response.data.data?.length);
            setLoading(false)
            document.getElementById("page").value = "10";
            setCurrentPage(1);
            setPageNo(10);
        });
    }

    const fromDate = (event) => {
        if (event._isAMomentObject == true) {
            setfromdate(event.format("YYYY-MM-DD"))
            settodate("");
        }
    }
    const toDate = (event) => {
        if (event._isAMomentObject == true) {
            settodate(event.format("YYYY-MM-DD"))
        }
    }
    const handleSearch = () => {
        customerFilter();
    }

    const getclear = () => {
        setOperatorNameError("");
        setAssignDateError("");
    }
    useEffect(() => {
        if (selectedValues.length == 0) {
            getclear()
        }
    }, [selectedValues]);

    const inputPropsdate = {
        placeholder: "DD/MM/YYYY",
        value: assignDate
    };

    const yesterday = moment().subtract(1, 'day');
    const disablePastDate = current => {
        return current.isAfter(yesterday);
    };
    const registrationDate = (event) => {
        if (event._isAMomentObject == true) {
            setAssignDate(event.format("YYYY-MM-DD"));
        }
    }

    console.log("currentPage", currentPage);

    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <h5 className="heading-text">Assign Customers</h5>
                    <div className="row">
                        <div className="col-xl-8">
                            <div className="common-heading-sec ">
                                <div className="search-bar">
                                    <input type="text" className="form-control" value={customerName} placeholder="Customer Name" onChange={(e) => setCustomerName(e.target.value)} />
                                </div>
                                <div className="search-bar">
                                    <input type="text" className="form-control" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                                </div>
                                <div className="search-bar">
                                    <input type="text" className="form-control" value={phoneNo} placeholder="Phone#" onChange={(e) => setPhoneNo(e.target.value.replace(/\D/g, ""))} />
                                </div>
                                <div className="datePickerBlock">
                                    {/* <input type="date" className="form-control" value={fromdate} onChange={(e) => setfromdate(e.target.value)} /> */}
                                    <Datetime inputProps={inputProps} timeFormat={false} dateFormat="YYYY-MM-DD"
                                        name="Date" onChange={fromDate} closeOnSelect={true}
                                        id="meeting_date" />
                                </div>
                                <div className="datePickerBlock">
                                    <Datetime inputProps={inputProps1} timeFormat={false} dateFormat="YYYY-MM-DD"
                                        name="Date" isValidDate={disablePastDt} onChange={toDate} closeOnSelect={true}
                                        id="meeting_date" />
                                    <div className="error-msgs">
                                        <p className="form-input-error" >{fromDateError}</p>
                                    </div>
                                </div>

                                <div className="search-clear" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass"></i>Find</div>
                                <div className="search-clear" onClick={handleclear}><i className="fa-solid fa-ban"></i>Clear</div>
                            </div>
                            </div>
                            <div className='col-xl-4'>
                                <div className='add-section'>
                                <div className="search-bar">
                                    <input type="search" className="form-control" placeholder="Name or Id or Phone#" value={search} onKeyDown={onKeydownAccountSearch} onChange={onSearch} />
                                </div>
                                </div>
                            </div>
                        
                    </div>


                    <div className=''>
                        <div className='row mt-2'>
                            <div className='col-lg-6 '>
                                <div className="page-filter">
                                    <span className="row-per-pages">Rows per page:</span>
                                    <div>
                                        <select name="page" id="page" onChange={(e) => setPageNo(e.target.value)}>
                                        <option value="10">10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6 assign-cus'>
                                <div className="col-lg-5 mb-12">
                                    <label htmlFor="operatorName" className="col-form-label">Operator Name <span className='mandatory ms-1'>*</span></label>
                                    <select className="form-select" id="operatorName" onChange={handleOperatorNameId} >
                                        <option style={{ display: "none" }}>Select OperatorName</option>
                                        {operatorName.length > 0 &&
                                            <>
                                                {operatorName.map((data) => <option key={data.operator_id} value={data.operator_id} >{data.operator_name}</option>)}
                                            </>
                                        }
                                    </select>
                                    <p className="form-input-error">{operatorNameError}</p>

                                </div>
                                {/* <div className="col-lg-2 mb-12">
                                <label htmlFor="assignDate" className="col-form-label" >Assign Date <span className='mandatory ms-1'>*</span></label>
                                <input type="date" className="form-control" id="assignDate" onChange={(e) => setAssignDate(e.target.value)} />
                                {CustError ?
                                    <p className="form-input-custerror">{CustError}</p> :
                                    <p className="form-input-error" >{assignDateError}</p>
                                }
                            </div> */}

                                <div className="col-lg-5 mb-12 form-group datePickerBlock ">
                                    <div className="tbox">
                                        <div className="textbox">
                                            <label htmlFor="assignDate" className="col-form-label" >AssignDate <span className='mandatory ms-1'>*</span></label>
                                            <Datetime inputProps={inputPropsdate}
                                                closeOnSelect={true}
                                                timeFormat={false}
                                                dateFormat="DD/MM/YYYY"
                                                name="Date"
                                                id="meeting_date"
                                                isValidDate={disablePastDate}
                                                onChange={registrationDate}
                                            />

                                            {CustError ?
                                                <p className="form-input-custerror">{CustError}</p> :
                                                <p className="form-input-error" >{assignDateError}</p>
                                            }
                                        </div>
                                    </div>
                                </div>



                                <div className="col-lg-2 mb-12">
                                    <div className="add-btn submit"/*  disable= {selectedValues.length>0 ?false:true} */ onClick={() => getSelectedReport()}  >Submit</div>
                                </div>
                            </div>
                        </div>


                    </div>
                    {loading ? <Loading /> :
                        <>
                            <div className="rounded table-responsive">
                                <table className="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Customer Name</th>
                                            <th>Email Id</th>
                                            <th>Phone No</th>
                                            <th>vehicle</th>
                                            <th>color</th>
                                            <th>stock</th>
                                            <th>source</th>
                                            <th>Date & Time</th>
                                            <th>Assign Status</th>
                                            <th>SelectAll <input className="form-check-input" type="checkbox"
                                                checked={newSelect.find((newSelect) => {
                                                    return newSelect.crntPage === currentPage
                                                }) ? true : false} id='SelectAll'
                                                onClick={() => { handleSelectField(customersInfo, "SelectAll", true, currentPage) }} /></th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {customersInfo?.length > 0 ? customersInfo.sort((a, b) => b.customer_id - a.customer_id).slice(currentPage == 1 ? 0 : (currentPage - 1) * pageLimit, (currentPage == 1 ? currentPage * pageLimit : currentPage * pageLimit))
                                            .map((customersInfo, index) => {
                                                onSelectedData = [...onSelectedData, customersInfo]
                                                return (<tr key={index}>
                                                    <td><a onClick={() => redirection(customersInfo.customer_id)}>{customersInfo.customer_id}</a></td>
                                                    <td><a onClick={() => redirection(customersInfo.customer_id)}>{customersInfo.customer_name}</a></td>
                                                    {customersInfo.email === null || customersInfo.email === "null" ? <td></td> : <td>{customersInfo.email}</td>}
                                                    <td>{formatMobileNO(customersInfo.Phone)}</td>
                                                    {customersInfo.vehicle === null || customersInfo.vehicle === "null" ? <td></td> : <td>{customersInfo.vehicle}</td>}
                                                    {customersInfo.color === null || customersInfo.color === "null" ? <td></td> : <td>{customersInfo.color}</td>}
                                                    {customersInfo.stock === null || customersInfo.stock === "null" ? <td></td> : <td>{customersInfo.stock}</td>}
                                                    {customersInfo.source === null || customersInfo.source === "null" ? <td></td> : <td>{customersInfo.source}</td>}
                                                    <td>{customersInfo.createdtime}</td>
                                                    { Assignstatus?.find( (value)=>{
                                                    console.log("value.customer_id",value.customer_id);
                                                        return value.customer_id === customersInfo.customer_id
                                                    })
                                                   ? <td>Assign</td>:<td> Not Assign</td>}
                                                   
                                                    <td>
                                                        <div>

                                                            <input className="form-check-input" type="checkbox" checked={customersInfo.isChecked} disabled={customersInfo.status != "Active"} inputId={`selectId${index}`} onClick={() => { handleSelectChangeField(customersInfo, currentPage); }} />
                                                        </div>
                                                    </td>
                                                </tr>)
                                            }
                                            ) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                                    </tbody>
                                </table>
                            </div>
                            <ul className="pagination">
                                <Pagination className=""
                                    totalRecords={totalRecords}
                                    pageLimit={pageLimit}
                                    pageRangeDisplayed={1}
                                    onChangePage={setCurrentPage}
                                />
                            </ul>
                        </>
                    }


                </div>
            </div>
            {isOpenPopup && (
                <CommonPopup
                    handleClose={togglePopup}
                    popupTitle={popupTitle}
                    popupMsg={popupMsg}
                    popupType={popupType}
                    popupActionType={popupActionType}
                    popupActionValue={popupActionValue}
                    popupActionPath={popupActionPath}
                />
            )}
        </div>
    );
};

export default AssignCustomer;