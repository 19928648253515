import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import SideBar from '../SideBar/sidebar';
import API from '../../Services/BaseService';
import Loading from '../../Component/Loading/Loading';
import Pagination from 'reactjs-hooks-pagination';
import { useNavigate } from 'react-router-dom';
// import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
// import { GridToolbar } from "@progress/kendo-react-grid";
// import { read, utils } from 'xlsx';
// import XLSX from 'xlsx';
//import { useSelector } from 'react-redux';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import ls from 'local-storage';
// import { CSVLink } from 'react-csv';
import moment from 'moment';
import EditManageZipCodePopup from './Uploadxl';
import EditManageRecyclePopup from './Recycle';
import Popup from "../../Component/Popup/Popup";
import RecycPopup from "../../Component/Popup/RecyclePopup";
import Reassign from './Reassign';
import SelectAllPopup from './SelectAllPopup';




const ReassignCustomers = () => {
    const navigate = useNavigate();
    // const userData = useSelector(state => state.LoginReducer.payload);
    const [PageNn, setPageNo] = useState(10);

    const pageLimit = PageNn;
    const [customersInfo, setCustomersInfo] = useState("");
    // const [Customers, setCustomers] = useState("");
    const [loading, setLoading] = useState(true)
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    
    // const [search, setSearch] = useState("");
    const [customerName, setCustomerName] = useState("");
    // const [email, setEmail] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [fromdate, setfromdate] = useState("");
    const [todate, settodate] = useState("");

    const [fromDateError, setFromDateError] = useState("");
    // const [date, setdate] = useState("");
    let onSelectedData = [];
    const [popupTitle, setPopupTitle] = useState("");
    const [popupMsg, setPopupMsg] = useState("");
    const [popupType, setPopupType] = useState("");
    const [popupActionType, setPopupActionType] = useState("");
    const [popupActionValue, setPopupActionValue] = useState("");
    const [isOpenPopup, setIsOpenPopup] = useState(false);

    const [operatorID, setoperatorID] = useState("");
    const [/* operator */, setoperator] = useState("");
    const [Selectedoperator, setSelectedoperator] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [isSelect, setIsSelect] = useState(false);
    const [IsRecycle, setIsRecycle] = useState(false);
    const [IsReassign, setIsReassign] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);
    console.log("selectedValues", selectedValues);
    
    const [selectedDontCall, setSelectedDontCall] = useState("");
    const [allDontCall, setAllDontCall] = useState("");
    const [newSelect, setNewSelect] = useState([]);
    const [Status, setStatus] = useState("")

    const [Count, setCount] = useState("");
    //  const [flagToggle, setFflagToggle] = useState(false)
    const [Filterstatus, setFilterstatus] = useState("");
    const [Filterstatus1, setFilterstatus1] = useState("");
    const [FilterAssign, setFilterAssign] = useState("");
    const [StatusID, setStatusID] = useState("");
    const [FilterSelect, setFilterSelect] = useState([]);
    const [PageselectAll, setPageselectAll] = useState(false);

    const [CompanyName, setCompanyName] = useState("");
    const [SelectedCompanyName, setSelectedCompanyName] = useState("");
    const [/* operator */, setCompanyid] = useState("");
    const [SelectedStatus, setSelectedStatus] = useState("");

    // const [CustomerID, setCustomerID] = useState("");

    // const [ListName, setListName] = useState("");
    // const [SelectedListName, setSelectedListName] = useState("");
    // const [ListId, setListId] = useState("");

    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };

    const toggleEditManageZipCodePopup = () => {
        setIsEdit(!isEdit);
    }

    const toggleSelectPopup = () => {
        setIsSelect(!isSelect);
    }

    const toggleEditManageRecyclePopup = () => {
        setIsRecycle(!IsRecycle);
    }
    const toggleEditManageReassignPopup = () => {
        setIsReassign(!IsReassign);
    }
    // useEffect(() => {
    //     const isPageFullyChecked = onSelectedData.every(data =>
    //         selectedValues.some(selected => selected.customer_id === data.customer_id)
    //     );

    //     if (currentPage === page && isPageFullyChecked) {
    //         setSelectAll(true);
    //         setSelect(true);
    //     } else {
    //         setSelectAll(false);
    //         setSelect(false);
    //     }
    // }, [currentPage, selectedValues, page]);



    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        setIsOpen(data);
    };
    function formatMobileNO(value) {
        var x = value.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
        value = '+1 ' + '(' + x[1] + ') ' + x[2] + '-' + x[3];
        return value;
    }

    useEffect(() => {
        handleCompanyName();
        StatusList();
        OperatorList();

        // handleListName()
    }, [])
    useEffect(() => {
        const dontCallCount = selectedValues.filter(selectedValues => selectedValues.status_name === "Don't Call").length;
        setSelectedDontCall(dontCallCount)
    }, [selectedValues])


    // const handleListName = () => {

    //     API.post("customerlistname/condition").then(response => {
    //         setListName(response.data.data);
    //     });
    // }

    // const handleListId = (e) => {
    //     setListId(e.target.value);
    //     setSelectedListName(
    //         ListName.filter((data) => data.customer_list_id == e.target.value)[0]
    //     );
    
    // };

    const OperatorList = () => {
        API.post('operatorlist/condition',).then((response) => {
            if (response.data.success == true) {
                setoperatorID(response.data.data);
            }
        });
    }

    const handleBuyerId = (e) => {
        setoperator(e.target.value);
        setSelectedoperator(
            operatorID.filter((data) => data.operator_id == e.target.value)[0]
        );
      
    };

    const handleCompanyName = () => {

        API.post("companyListview/condition").then(response => {
            setCompanyName(response.data.data);
        });
    }

    const StatusList = () => {
        API.post('callstatuslist/condition',).then((response) => {
            if (response.data.success == true) {
                setStatusID(response.data.data);

            }
        });
    }

    // const customerListforxls = () => {

    //     API.post("customerListforxls/condition").then(response => {
    //         setCustomers(response.data.data);
    //     });
    // }

    //select allpage
    const customerSelectAll = () => {
        let request = {
            customer_name: customerName,
            company_id: !SelectedCompanyName ? "" : SelectedCompanyName.company_id,
            customer_list_id: "",
            email: "",
            phone_no: phoneNo,
            from_date: fromdate,
            to_date: todate,
            status: Filterstatus,
            Assignstatus: FilterAssign,
            customer_status: !SelectedStatus ? "" : SelectedStatus.call_status_id,
            data: "",
            operator_id: !Selectedoperator ? "" : Selectedoperator.operator_id,
        }

        API.post("customerselectall/condition", request).then(response => {
            setFilterSelect("");
            setFilterSelect(response.data?.data.map(data => ({ customer_id: data.customer_id })));
        });
    }



    useEffect(() => {
        setCustomersInfo(customersInfo);

    }, [customersInfo])



    useEffect(() => {
        CustomersListInfo();
        // customerListforxls();
        customerSelectAll();
    }, [currentPage, pageLimit])

    const CustomersListInfo = () => {

        let request = {

            customer_name: customerName,
            company_id: !SelectedCompanyName ? "" : SelectedCompanyName.company_id,
            customer_list_id: "",
            email: "",
            phone_no: phoneNo,
            from_date: fromdate,
            to_date: todate,
            status: Filterstatus,
            Assignstatus: FilterAssign,
            customer_status: !SelectedStatus ? "" : SelectedStatus.call_status_id,
            rowsPerPage: pageLimit,
            pageNumber: currentPage,
            data: "",
            operator_id: !Selectedoperator ? "" : Selectedoperator.operator_id,

        }

        API.post('customerfilter1/condition', request).then((response) => {
            if (response.data.success == true) {

                setCustomersInfo(response.data?.data.map((data) => { return { ...data, isChecked: selectedValues.some(selected => selected.customercompany_mapid === data.customercompany_mapid), currentPage: currentPage } }));
                setCount(response.data.data1[0].count);

                // setstatus_name(response.data?.data.status_name);
                setTotalRecords(response.data.data1[0].count);
                setAllDontCall(response.data.data2[0].count)
                /* document.getElementById("page").value = "10"; */
                // setflag(false);
                /*  setPageNo(10); */
                response.data?.data.map((data, index) => {
                    const touggle = document.getElementById(`touggle${index}`)
                    if (touggle != null) {
                        if (data.status === "Active") {
                            touggle.checked = true;
                        }
                        else {
                            touggle.checked = false;
                        }
                    }

                }
                )

                //  setCustomers(response.data.data);
            }
            setLoading(false);
            // setCurrentPage(1);
        });
    }


    //afterclear calling list function
    const CustomersListInfoClear = () => {

        let request = {

            customer_name: "",
            company_id: "",
            customer_list_id: "",
            email: "",
            phone_no: "",
            from_date: "",
            to_date: "",
            status: "",
            data: "",
            Assignstatus: "",
            customer_status: "",
            rowsPerPage: "10",
            pageNumber: "1",
            operator_id: !Selectedoperator ? "" : Selectedoperator.operator_id,

        }

        API.post('customerfilter1/condition', request).then((response) => {
            if (response.data.success == true) {

                setCustomersInfo(response.data?.data.map((data) => { return { ...data, isChecked: false, currentPage: currentPage } }));
                setCount(response.data.data1[0].count);
                setTotalRecords(response.data.data1[0].count);
                setAllDontCall(response.data.data2[0].count)

                response.data?.data.map((data, index) => {
                    const touggle = document.getElementById(`touggle${index}`)
                    if (touggle != null) {
                        if (data.status === "Active") {
                            touggle.checked = true;
                        }
                        else {
                            touggle.checked = false;
                        }
                    }

                }
                )

            }
            setLoading(false);

        });
        API.post('customerList/condition', request).then((response) => {
            if (response.data.success == true) {

                setCustomersInfo(response.data?.data.map((data) => { return { ...data, isChecked: false, currentPage: "" } }));
                setCount(response.data.data1[0].count);
                setTotalRecords(response.data.data1[0].count);

                response.data?.data.map((data, index) => {
                    const touggle = document.getElementById(`touggle${index}`)
                    if (touggle != null) {
                        if (data.status === "Active") {
                            touggle.checked = true;
                        }
                        else {
                            touggle.checked = false;
                        }
                    }

                }
                )

            }
            setLoading(false);

        });
    }



    // const exportExport = () => {
    //     if (_export.current !== null) {
    //         _export.current.save();
    //     }
    // };

    // const [data, setData] = useState(null);
    // const handleFileUpload = (event) => {
    //     const file = event.target.files[0];

    //     const reader = new FileReader();

    //     reader.onload = (event) => {
    //         const binaryData = event.target.result;

    //         const workbook = read(binaryData, { type: 'binary' });

    //         const worksheet = workbook.Sheets['Sheet1'];

    //         const sheetData = utils.sheet_to_json(worksheet, { header: 1 });


    //         const columnNames = ['first_name', 'last_name', 'email', '', "Phone"];

    //         const namedArray = sheetData.map((innerArray) => {
    //             return innerArray.reduce((acc, curr, index) => {
    //                 acc[columnNames[index]] = curr;
    //                 return acc;
    //             }, {});
    //         });

    //         // const namedArray2 = sheetData.map((innerArray) => {
    //         //     return innerArray.map((value) => {
    //         //       if (typeof value === 'string' && value.match(/^\(\d{3}\) \d{3}-\d{4}$/)) {
    //         //         // Phone number is in US format, so normalize it
    //         //         return value.replace(/\D/g, '');
    //         //       } else {
    //         //         // Value is not a phone number, so return as-is
    //         //         return value;
    //         //       }
    //         //     });
    //         //   });
    //         const array = namedArray;
    //         const normalizedArray = array.map(obj => {
    //             const normalizedPhoneNumber = obj.Phone.replace(/\D+/g, '');
    //             return {
    //                 ...obj,
    //                 Phone: normalizedPhoneNumber
    //             };
    //         });


    //         setData(normalizedArray);
    //     };



    //     reader.readAsBinaryString(file);
    // };
    const handleCompanyId = (e) => {
        setCompanyid(e.target.value);
        setSelectedCompanyName(
            CompanyName.filter((data) => data.company_id == e.target.value)[0]
        );
     
    };





    //inactive excel
    // let previousFile = null;
    // const ImportInactive = ($event) => {
    //     const files = $event.target.files;
    //     if (files.length) {
    //         const file = files[0];
    //         if (file !== previousFile) {

    //             previousFile = file;
    //             $event.target.value = null;
    //         }
    //         const reader = new FileReader();
    //         reader.onload = (event) => {
    //             const binaryData = event.target.result;
    //             const workbook = read(binaryData, { type: 'binary' });
    //             const sheetName = workbook.SheetNames[0];
    //             const worksheet = workbook.Sheets[sheetName];

    //             const sheetData = utils.sheet_to_json(worksheet, { header: 1 }); // Include header row

    //             const columnNames = ['first_name', 'last_name', 'vehicle', 'version', 'Phone', 'source', 'email', 'color']; // Header row
    //             // sheetData.shift(); // Remove header row from data

    //             const validNumbers = sheetData
    //                 .filter(innerArray => innerArray[4] && String(innerArray[4]).match(/^\d{3}-\d{3}-\d{4}$/) && !/[a-zA-Z]/.test(innerArray[4]))
    //                 .map(innerArray => {
    //                     const normalizedPhoneNumber = String(innerArray[4]).replace(/[-]/g, '');
    //                     return {
    //                         first_name: innerArray[0],
    //                         last_name: innerArray[1],
    //                         vehicle: innerArray[2],
    //                         version: innerArray[3],
    //                         source: innerArray[5],
    //                         email: innerArray[6],
    //                         color: innerArray[7],
    //                         [columnNames[4]]: normalizedPhoneNumber
    //                     };
    //                 });

    //             let request = {
    //                 customerlist: validNumbers,
    //             }
    //             if (validNumbers.length == 0) {
    //                 togglePopup();

    //                 setPopupTitle("Upload Error");
    //                 setPopupMsg("No valid phone numbers found in the uploaded file.");
    //                 setPopupType("error");
    //                 setPopupActionType("close");
    //                 setPopupActionValue("ok");



    //             }
    //             else {

    //                 API.post('customerinactive/update', request).then((response) => {
    //                     if (response.data.success == true) {
    //                         togglePopup();
    //                         setPopupTitle("Customer");
    //                         setPopupMsg("Customer Inactive Successfully");
    //                         setPopupType("success");
    //                         setPopupActionType("close");
    //                         setPopupActionValue("ok");
    //                         setLoading(true)
    //                         CustomersListInfo();
    //                     } else {
    //                         togglePopup();
    //                         setPopupTitle("Customer");
    //                         setPopupMsg(response.data.error.err);
    //                         setPopupType("error");
    //                         setPopupActionType("close");
    //                         setPopupActionValue("close");
    //                     }
    //                     $event.target.value = null;
    //                 });
    //             }
    //         }
    //         reader.readAsArrayBuffer(file);
    //     }
    // }





    // const onSearch = (e) => {
    //     setSearch(e.target.value);
    // }

    // useEffect(() => {
    //     if (search != "") {
    //         const timer = setTimeout(() => {
    //             CustomerSearch();
    //         }, 200);
    //         return () => {
    //             clearTimeout(timer);
    //         };
    //     } else {
    //         setLoading(true);
    //         CustomersListInfo();
    //     }
    // }, [search]);

    // const onKeydownAccountSearch = (event) => {
    //     if (event.key === "Enter") {
    //         CustomerSearch();
    //     }
    // };
    const inputProps = {
        placeholder: "From Date",
        value: fromdate
    };
    const inputProps1 = {
        placeholder: "To Date",
        value: todate
    };

    const fromDate = (event) => {
        if (event._isAMomentObject == true) {
            setfromdate(event.format("YYYY-MM-DD"))
            settodate("");
        }
    }
    const toDate = (event) => {
        if (event._isAMomentObject == true) {
            settodate(event.format("YYYY-MM-DD"))
        }
    }

    const customerFilter1 = () => {

        setFromDateError("")

        if (fromdate) {
            if (!todate) {
                setFromDateError("To Date is required")
                return;
            }

        }

        setLoading(true)
        customerSelectAll();
        let request = {
            customer_name: customerName,
            company_id: !SelectedCompanyName ? "" : SelectedCompanyName.company_id,
            customer_list_id: "",
            email: "",
            phone_no: phoneNo,
            from_date: fromdate,
            to_date: todate,
            status: Filterstatus,
            Assignstatus: FilterAssign,
            customer_status: !SelectedStatus ? "" : SelectedStatus.call_status_id,
            rowsPerPage: pageLimit,
            pageNumber: currentPage,
            data: "",
            operator_id: !Selectedoperator ? "" : Selectedoperator.operator_id,

        }

        API.post("customerfilter1/condition", request).then(response => {
            setCustomersInfo(response.data?.data.map((data) => { return { ...data, isChecked: false, currentPage: currentPage } }));

            setCount(response.data.data1[0].count);
            setAllDontCall(response.data.data2[0].count)


            setTotalRecords(response.data.data1[0].count);
            setLoading(false)

            /*   document.getElementById("page").value = "10";
              setCurrentPage(1);
              setPageNo(10); */
        });
    }


    //toggle service
    const editable = (data, id) => {
        let statusChecked = document.getElementById(id)
        if (data.status_name === "Don't Call") {
            togglePopup();
            setPopupTitle("Edit Customer");
            if (data.status === "Active") {
                setPopupMsg("Are You Want To Customer Inactive ");
            }
            else {
                setPopupMsg("Are You Want To Customer Active ");
            }
            setPopupType("success");
            setPopupActionValue("ok");
            setPopupActionType("close");
            // setPopupActionPath("/customers");
        }


        let request = {
            customer_id: data.customer_id,
            status: !statusChecked.checked ? "Inactive" : "Active"
        }
        API.post("customervoidbyid/update", request).then(response => {

            if (response.data.success) {
                /* document.getElementById("page").value = "10";
                setCurrentPage(1); */
                setLoading(true);
                CustomersListInfo()

            }
        });

    }

    const handleSelectField = (item, id, flag, crntPage) => {
        setPageselectAll(false);

        const checkbox = document.getElementById(id);
        const filter = newSelect.filter(data => data.crntPage === crntPage);

        setNewSelect(filter.length ? newSelect.filter(item => item.crntPage !== crntPage) : [...newSelect, { crntPage }]);

        if (checkbox && checkbox.checked) {

            const selValues = [...selectedValues, ...onSelectedData.map((data) => ({
                "customer_id": data.customer_id,
                "status_name": data.status_name,
                "customercompany_mapid":data?.customercompany_mapid
            }))];

            const uniqueSelectedValues = selValues.reduce((acc, current) => {
                if (!acc.find(item => item.customercompany_mapid === current.customercompany_mapid)) {
                    acc.push(current);
                }
                return acc;
            }, []);

            setSelectedValues(uniqueSelectedValues);

            setCustomersInfo(customersInfo.map(data => ({
                ...data,
                isChecked: true,
                currentPage: crntPage
            })));
        } else {


            const filteredValues = selectedValues.filter(data =>
                !onSelectedData.some(item => item.customercompany_mapid === data.customercompany_mapid)
            );
            setSelectedValues(filteredValues);

            setCustomersInfo(customersInfo.map(data => ({
                ...data,
                isChecked: false,
                currentPage: ''
            })));
        }
    };



    const handleSelectChangeField = (items) => {
        setPageselectAll(false);

        const isAlreadySelected = selectedValues.some((data) => data.customercompany_mapid === items.customercompany_mapid);

        if (isAlreadySelected) {
            // Uncheck the item
            const updatedSelectedValues = selectedValues.filter(data => data.customercompany_mapid !== items.customercompany_mapid);
            setSelectedValues(updatedSelectedValues);

            // Uncheck the item in customersInfo
            setCustomersInfo(customersInfo.map(data =>
                data.customercompany_mapid === items.customercompany_mapid ? { ...data, isChecked: false } : data
            ));

            // Check if all items on the current page are unchecked
        

           
        } else {
            // Check the item
            const newSelectedValues = [...selectedValues, { customer_id: items.customer_id, status_name: items.status_name,customercompany_mapid:items?.customercompany_mapid }];
            setSelectedValues(newSelectedValues);

            setCustomersInfo(customersInfo.map(data =>
                data.customercompany_mapid === items.customercompany_mapid ? { ...data, isChecked: true } : data
            ));

            // Check if all items on the current page are selected
         

        }
    };


    const handleSearch = () => {
        setFilterstatus1(Filterstatus)
        customerFilter1();
    }

    const handleclear = () => {

        setCustomerName("");
        setPhoneNo("");
        setCompanyName("");
        setStatusID("");
        setfromdate("");
        settodate("");
        setFromDateError("");
        // setCustError("");
        // setStatusError("");
        setStatus("")
        setSelectedCompanyName("")
        setSelectedStatus("")
        setFilterAssign("")
        setFilterstatus("")
        setFilterstatus1("")
        setoperatorID("");
        setSelectedCompanyName("");
        setSelectedoperator("");
        setSelectedStatus("");
        const statusSelect = document.getElementById("status");
        statusSelect.selectedIndex = 0;
        const Filterstatus = document.getElementById("Filterstatus");
        Filterstatus.selectedIndex = 0;

        setSelectedValues("");
        setLoading(true);
        handleCompanyName();
        CustomersListInfoClear();
        StatusList();
        OperatorList();

        // setCurrentPage(1);
        //document.getElementById("page").value = "10";

        // document.getElementById("userName").value = "Select userName";
        setSelectedValues([])

        setNewSelect([]);
        for (let i = 0; i < customersInfo.length; i++) {
            const sorting = customersInfo;
            sorting[i].isChecked = false;
            sorting[i].currentPage = '';
            setCustomersInfo(sorting);
        }
    }
    const disablePastDt = current => {
        return current.isSameOrAfter(fromdate);
    };

    const redirection = (id) => {

        navigate({ pathname: "/CustomerView" });
        ls.set("AdminId", id);
    };

    //sorting

    const [sorted, setSorted] = useState({ sorted: "id", reversed: false })

    const sortByID = () => {
        const usersCopy = [...customersInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userA.customer_id - userB.customer_id;
            }
            return userB.customer_id - userA.customer_id;
        });
        // usersCopy.sort((userA, userB) =>
        // userA.customer_id > userB.customer_id ? (userB.customer_id - userA.customer_id) : (userA.customer_id - userB.customer_id))
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "id", reversed: !sorted.reversed });

    };

    const sortByCustomerName = () => {
        const usersCopy = [...customersInfo];

        usersCopy.sort((userA, userB) => {
            userA = userA.customername || '';
            userB = userB.customername || '';
            if (sorted.reversed) {
                return userB.localeCompare(userA);
            }

            return userA.localeCompare(userB);

        });
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "customername", reversed: !sorted.reversed });

    };



    // const sortByEmail = () => {
    //     const usersCopy = [...customersInfo];
    //     usersCopy.sort((userA, userB) => {
    //         userA = userA.email || '';
    //         userB = userB.email || '';
    //         if (sorted.reversed) {
    //             return userB.localeCompare(userA);
    //         }

    //         return userA.localeCompare(userB);

    //     });
    //     setCustomersInfo(usersCopy);
    //     setSorted({ sorted: "email", reversed: !sorted.reversed });
    // };

    const sortByPhone = () => {
        const usersCopy = [...customersInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userA.Phone - userB.Phone;
            }
            return userB.Phone - userA.Phone;
        });
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "phone", reversed: !sorted.reversed });
    };

    const sortByComment = () => {
        const usersCopy = [...customersInfo];

        usersCopy.sort((userA, userB) => {
            userA = userA.customername || '';
            userB = userB.customername || '';
            if (sorted.reversed) {
                return userB.localeCompare(userA);
            }

            return userA.localeCompare(userB);

        });
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "CommentsByAdmin", reversed: !sorted.reversed });
    };

    const sortByCompanyName = () => {
        const usersCopy = [...customersInfo];

        usersCopy.sort((userA, userB) => {
            userA = userA.company_name || '';
            userB = userB.company_name || '';
            if (sorted.reversed) {
                return userB.localeCompare(userA);
            }

            return userA.localeCompare(userB);

        });
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "company_name", reversed: !sorted.reversed });

    };

    const sortByListName = () => {
        const usersCopy = [...customersInfo];

        usersCopy.sort((userA, userB) => {
            userA = userA.list_name || '';
            userB = userB.list_name || '';
            if (sorted.reversed) {
                return userB.localeCompare(userA);
            }

            return userA.localeCompare(userB);

        });
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "list_name", reversed: !sorted.reversed });

    };
    const sortByOperatorName = () => {
        const usersCopy = [...customersInfo];

        usersCopy.sort((userA, userB) => {
            userA = userA.operatorname || '';
            userB = userB.operatorname || '';
            if (sorted.reversed) {
                return userB.localeCompare(userA);
            }

            return userA.localeCompare(userB);

        });
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "operatorname", reversed: !sorted.reversed });

    };

    const sortByDatetime = () => {
        const usersCopy = [...customersInfo];
        usersCopy.sort((userA, userB) => {
            if (sorted.reversed) {
                return userB.createdtime.localeCompare(userA.createdtime);
            }

            return userA.createdtime.localeCompare(userB.createdtime);

        });
        setCustomersInfo(usersCopy);
        setSorted({ sorted: "createdtime", reversed: !sorted.reversed });
    };

    // const handleDropdownChange = (event) => {
    //     setStatus(event.target.value);

    // };

    function formatTime(value) {
        let local = ""
        let offset = ""
        // let utc1 = ""
        local = new Date(value);

        //var local = new Date("2023-05-22 08:21:11");
        offset = local.getTimezoneOffset();
        value = new Date(local.getTime() - offset * 60000);
        value = moment(value).format('MMMM D, YYYY hh:mm A')
        // let utc2222 = utc1
        return value;
    }

    // const ZipcodePopup = () => {
    //     toggleEditManageZipCodePopup()
    // }

    // const SelectCheckBoxPopup = () => {
    //     setCustError("");
    //     setStatusError("");
    //     if (PageselectAll === false) {
    //         if (selectedValues.length === 0) {
    //             setCustError("Customer Name is required");
    //             return;
    //         }
    //     }
    //     if (!Status) {
    //         setStatusError(" Status is required");
    //         return;
    //     }
    //     toggleSelectPopup();
    // }


    // const RecyclePopup = () => {

    //     toggleEditManageRecyclePopup()
    // }
    const ReassignPopup = () => {

        toggleEditManageReassignPopup()
    }
    const reCallFunction = () => {
        CustomersListInfo();
    }

    useEffect(() => {
        if (selectedValues.length === 0) {
            setCurrentPage(1);
            // setLoading(true);
            setPageselectAll(false);
            setStatus("");
            setNewSelect([]);
    
            CustomersListInfo(); 
        }
    }, [selectedValues]); 
    
    const reCallFunction1 = () => {
        setLoading(true);
        setSelectedValues([]); 
    };


    // const inputPropsdate = {
    //     placeholder: "DD/MM/YYYY",
    //     value: date
    // };

    // const yesterday = moment().subtract(1, 'day');
    // const disablePastDate = current => {
    //     return current.isAfter(yesterday);
    // };
    // const registrationDate = (event) => {
    //     if (event._isAMomentObject == true) {
    //         setdate(event.format("YYYY-MM-DD"));
    //     }
    // }
    const handleStatusId = (e) => {
        setStatus(e.target.value);
        setSelectedStatus(
            StatusID.filter((data) => data.call_status_id == e.target.value)[0]
        );
      
    };

    // const handleAllPageSelectField = (PageselectAll) => {

    //     const updatedCustomersInfo = customersInfo.map((customer) => ({
    //         ...customer,
    //         isChecked: false, 
    //     }));
    //     setFlag("");
    //     setNewSelect([]);
    //     setSelectedPage("");
    //     setPage("");
    //     setSelectedValues([]);
    //     setLen(0);
    //     setCustomersInfo(updatedCustomersInfo);
    //     setPageselectAll(!PageselectAll);
    // };



    //const  marginTop1 =  PageselectAll.length>0 ? '50px': '10px';


    //ImportDontcall excel
    // let previousDontcallFile = null;
    // const ImportDontCall = ($events) => {
    //     const files = $events.target.files;
    //     if (files.length) {
    //         const file = files[0];
    //         if (file !== previousDontcallFile) {

    //             previousDontcallFile = file;
    //             $events.target.value = null;
    //         }
    //         const reader = new FileReader();
    //         reader.onload = (event) => {
    //             const binaryData = event.target.result;
    //             const workbook = read(binaryData, { type: 'binary' });
    //             const sheetName = workbook.SheetNames[0];
    //             const worksheet = workbook.Sheets[sheetName];

    //             const sheetData = utils.sheet_to_json(worksheet, { header: 1 }); // Include header row

    //             const columnNames = ['first_name', 'last_name', 'vehicle', 'version', 'Phone', 'source', 'email', 'color']; // Header row
    //             // sheetData.shift(); // Remove header row from data

    //             const validNumbers = sheetData
    //                 .filter(innerArray => innerArray[4] && String(innerArray[4]).match(/^\d{3}-\d{3}-\d{4}$/) && !/[a-zA-Z]/.test(innerArray[4]))
    //                 .map(innerArray => {
    //                     const normalizedPhoneNumber = String(innerArray[4]).replace(/[-]/g, '');
    //                     return {
    //                         first_name: innerArray[0],
    //                         last_name: innerArray[1],
    //                         vehicle: innerArray[2],
    //                         version: innerArray[3],
    //                         source: innerArray[5],
    //                         email: innerArray[6],
    //                         color: innerArray[7],
    //                         [columnNames[4]]: normalizedPhoneNumber
    //                     };
    //                 });

    //             let request = {
    //                 customerlist: validNumbers,
    //             }
    //             if (validNumbers.length == 0) {
    //                 togglePopup();
    //                 setPopupTitle("Upload Error");
    //                 setPopupMsg("No valid phone numbers found in the uploaded file.");
    //                 setPopupType("error");
    //                 setPopupActionType("close");
    //                 setPopupActionValue("ok");



    //             }
    //             else {
    //                 API.post('customerdontcalls/update', request).then((response) => {
    //                     setLoading(false)
    //                     if (response.data.success == true) {
    //                         togglePopup();
    //                         setPopupTitle("Customer");
    //                         setPopupMsg("Customer Dontcall Successfully");
    //                         setPopupType("success");
    //                         setPopupActionType("close");
    //                         setPopupActionValue("ok");
    //                         setLoading(true)
    //                         CustomersListInfo();
    //                     } else {
    //                         togglePopup();
    //                         setPopupTitle("Customer Dontcall");
    //                         setPopupMsg(response.data.error.err);
    //                         setPopupType("error");
    //                         setPopupActionType("close");
    //                         setPopupActionValue("close");
    //                     }
    //                     $events.target.value = null;
    //                 });
    //             }
    //         }
    //         reader.readAsArrayBuffer(file);
    //     }
    // }







    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">
                    <div className="back-btn z-index" onClick={() => navigate('/customers')}><i className="fa-sharp fa-solid fa-arrow-left me-1"></i>Back</div>

                    <h5 className="heading-text">Reassign Customers</h5>
                    <div className="row mb-3">

                        {/* <div className='d-flex justify-content-end pes-link xl-buttons'>
                            <div className="custom-file me-2">
                                <input type="file" name="file" className="custom-file-input" id="inputGroupFile" required onChange={ImportInactive}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" hidden />
                                <label className="custom-file-label" htmlFor="inputGroupFile"><i className="fa-solid fa-upload"></i>INACTIVE</label>
                            </div>
                           
                            <CSVLink data={Customers} filename="customer" className="download-xl me-2">Download XL</CSVLink>
                           
                            <div className="custom-file me-2" onClick={() => { ZipcodePopup() }}><i className="fa-solid fa-cloud-arrow-up me-1"></i>Upload XL</div>

                            <div className="custom-file me-2">
                                <input type="file" name="file" className="custom-file-input" id="inputDontCall" required onChange={ImportDontCall}
                                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" hidden />
                                <label className="custom-file-label" htmlFor="inputDontCall"><i className="fa-solid fa-phone-slash"></i>Don&rsquo;t call</label>
                            </div>

                        </div> */}





                        <div className="common-heading-sec cus">
                            <div className="col-lg-2 me-2">
                                {/* <label className="form-label">
                                        Operator
                                    </label> */}
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    id="Sale"
                                    onChange={handleCompanyId}
                                >
                                    <option style={{ display: "none" }}>Company Name</option>
                                    {CompanyName?.length > 0 && (
                                        <>
                                            {CompanyName.map((CompanyNameID) => (
                                                <option
                                                    key={CompanyNameID.company_id}
                                                    value={CompanyNameID.company_id}
                                                >
                                                    {CompanyNameID.company_name}
                                                </option>
                                            ))}
                                        </>
                                    )}
                                </select>
                            </div>
                            <div className=" col-lg-2 me-2">
                                {/* <label className="form-label">
                                        Operator
                                    </label> */}
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    id="Sale"
                                    onChange={handleStatusId}
                                    defaultValue={Status}
                                >
                                    <option style={{ display: "none" }} >Call Status</option>
                                    {StatusID?.length > 0 && (
                                        <>
                                            {StatusID.map((StatusID) => (
                                                <option
                                                    key={StatusID.call_status_id}
                                                    value={StatusID.call_status_id}
                                                >
                                                    {StatusID.status_name}
                                                </option>
                                            ))}
                                        </>
                                    )}
                                </select>
                            </div>
                            <div className="col-lg-2 me-2">
                                {/* <label htmlFor="status" className="col-form-label">Status <span className='mandatory ms-1'>*</span></label> */}
                                <select className="form-select" id="status" onChange={(e) => setFilterAssign(e.target.value)}>
                                    <option selected style={{ display: "none" }}  >Assign Status</option>
                                    <option value="Assign">Assign</option>
                                    <option value="Not Assign">Not Assign</option>
                                </select>
                            </div>
                            <div className="col-lg-2 me-2">
                                {/* <label htmlFor="status" className="col-form-label">Status <span className='mandatory ms-1'>*</span></label> */}
                                <select className="form-select" id="Filterstatus" onChange={(e) => setFilterstatus(e.target.value)}>
                                    <option selected style={{ display: "none" }}  >Status</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                    <option value="Dontcall">Don&rsquo;t call</option>
                                </select>
                            </div>
                            <div className=" col-lg-2 me-2">
                                {/* <label className="form-label">
                                        Operator
                                    </label> */}
                                <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    id="Sale"
                                    onChange={handleBuyerId}
                                >
                                    <option style={{ display: "none" }}>Operator Name</option>
                                    {operatorID?.length > 0 && (
                                        <>
                                            {operatorID.map((operatorID) => (
                                                <option
                                                    key={operatorID.operator_id}
                                                    value={operatorID.operator_id}
                                                >
                                                    {operatorID.operatorname}
                                                </option>
                                            ))}
                                        </>
                                    )}
                                </select>
                            </div>



                        </div>





                    </div>
                    <div className='row mb-2'>
                        {/* <div className=" col-lg-2 phone">
                            <input type="text" className="form-control" value={email} placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                        </div> */}
                        <div className=" col-lg-2 me-2">
                            <input type="text" className="form-control" value={customerName} placeholder="Customer Name" onChange={(e) => setCustomerName(e.target.value)} />
                        </div>
                        <div className=" col-lg-2 phone ">
                            <input type="text" className="form-control" value={phoneNo} placeholder="Phone#" maxLength={10} onChange={(e) => setPhoneNo(e.target.value.replace(/\D/g, ""))} />
                        </div>
                        <div className=" col-lg-2 datePickerBlock ">
                            {/* <input type="date" className="form-control" value={fromdate} onChange={(e) => setfromdate(e.target.value)} /> */}
                            <Datetime inputProps={inputProps} timeFormat={false} dateFormat="YYYY-MM-DD"
                                name="Date" onChange={fromDate} closeOnSelect={true}
                                id="meeting_date" />
                        </div>
                        <div className="col-lg-2 datePickerBlock">
                            <Datetime inputProps={inputProps1} timeFormat={false} dateFormat="YYYY-MM-DD"
                                name="Date" isValidDate={disablePastDt} onChange={toDate} closeOnSelect={true}
                                id="meeting_date" />
                            <div className="error-msgs">
                                <p className="form-input-error" >{fromDateError}</p>
                            </div>
                        </div>

                        {/* <div className="col-lg-2 me-2">
                                            <label htmlFor="customerName" className="form-label">List Name </label>
                                            <select
                                                className="form-select"
                                                aria-label="Default select example"
                                                id="Sale"
                                                onChange={handleListId}

                                            >
                                                <option style={{ display: "none" }}>Select ListName</option>
                                                {ListName?.length > 0 && (
                                                    <>
                                                        {ListName.map((ListNameID) => (
                                                            <option
                                                                key={ListNameID.customer_list_id}
                                                                value={ListNameID.customer_list_id}
                                                            >
                                                                {ListNameID.list_name}
                                                            </option>
                                                        ))}
                                                    </>
                                                )}
                                            </select>
                                        </div> */}
                        <div className="col-lg-2 filterfind">
                            <div className="search-clear" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass "></i>Find</div>
                            <div className="search-clear" onClick={handleclear}><i className="fa-solid fa-ban"></i>Clear</div>
                        </div>
                    </div>

                    <div className="common-heading-sec col-lg-12 pageno">
                        <span className="row-per-page">Rows per page:
                            <span>
                                <div>
                                    <select name="page" id="page" onChange={(e) => setPageNo(e.target.value)}>
                                        <option value="10">10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                            </span>
                            <div className="count">
                                <span> Count :{Count}</span>
                            </div>

                            {/* <div className='PageselectAll'>
                                <span> Select All Pages</span>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={PageselectAll}

                                    onChange={() => { handleAllPageSelectField(PageselectAll) }} />


                            </div> */}

                        </span>
                        {selectedValues.length > 0 || PageselectAll ?
                            <>
                                <div className="col-lg-1 activestatus1">
                                    <div className="add-btn" onClick={() => { ReassignPopup() }} >Reassign</div>

                                </div>

                            </> :
                            <> </>
                        }
                        {/* <div className='col-xl-5 custaddrecycle'>
                            <div className="search-overall me-2">
                                <input type="search" className="form-control" placeholder="Name or Id or Phone#" onKeyDown={onKeydownAccountSearch} onChange={onSearch} />
                            </div>
                            <div className='add-section'>

                                <div className="add-btn" onClick={() => navigate("/customersAdd")}><i className="fa-solid fa-plus"></i>Add</div>
                             


                            </div>
                        </div> */}

                    </div>



                    {loading ? <Loading /> :
                        <>
                            <div className="rounded table-responsive" >
                                <table className="table bg-white" >
                                    <thead>
                                        <tr>
                                            <th onClick={sortByID}>#<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByCustomerName}>Customer Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            {/* <th onClick={sortByEmail}>Email Id<i className="fa fa-sort" aria-hidden="true"></i></th> */}
                                            <th onClick={sortByPhone}>Phone#<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByComment}>Comments<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByDatetime}>Date & Time<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            {/* <th>Status</th> */}
                                            <th onClick={sortByCompanyName}>Company Name <i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByListName}>List Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByOperatorName}>Operator Name<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th>Status</th>
                                            {/* <th>Inactive <input  className="form-check-input"  type="checkbox"  id="SelectAll" onClick={() => { handleSelectField(customersInfo, "SelectAll") }}/></th> */}

                                            <th>Call Status</th>
                                            <th className='text-center action-right-fix-list'>SelectAll <input className="form-check-input" type="checkbox"
                                                checked={customersInfo.every(data => data.isChecked)}
                                                id='SelectAll'
                                                onClick={() => { handleSelectField(customersInfo, "SelectAll", true, currentPage) }} /></th>

                                            {/* <th className='text-center action-right-fix-list'>Action</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {customersInfo.length > 0 ? (
                                            customersInfo.sort((a, b) => b.customersInfo - a.customersInfo)
                                                // .slice((currentPage) * pageLimit, currentPage * pageLimit)
                                                .map((customersInfo, index) => {
                                                    onSelectedData = [...onSelectedData, customersInfo]
                                                    return (<tr key={index}>
                                                        <td><a onClick={() => redirection(customersInfo.customer_id)}>{customersInfo.customer_id}</a></td>
                                                        <td><a onClick={() => redirection(customersInfo.customer_id)}>{customersInfo.customername}</a></td>
                                                        {/* <td>{customersInfo.email}</td> */}
                                                        {/* {customersInfo.email === null || customersInfo.email === "null" ? <td></td> : <td>{customersInfo.email}</td>} */}
                                                        {/* <td></td> */}
                                                        {customersInfo.Phone === null || customersInfo.Phone === "null" ? <td></td> : <td>{formatMobileNO(customersInfo.Phone)}</td>}
                                                        {/* <td>{customersInfo.CommentsByAdmin}</td> */}
                                                        {customersInfo.CommentsByAdmin === null || customersInfo.CommentsByAdmin === "null" ? <td></td> : <td>{customersInfo.CommentsByAdmin}</td>}
                                                        <td>{formatTime(customersInfo.createdtimes)}</td>

                                                        {/* <td>{customersInfo.active === 1 ?
                                                        <span className="active-color">Active</span> :
                                                        <span className="inactive-color">Inactive</span>
                                                    }</td> */}
                                                        <td>{customersInfo.company_name}</td>
                                                        <td>{customersInfo.list_name}</td>
                                                        <td>{customersInfo.operatorname}</td>

                                                        <td>
                                                            <div className="form-check form-switch">
                                                                <input className="form-check-input" type="checkbox" id={`touggle${index}`} checked={customersInfo.status === "Active"} onClick={() => editable(customersInfo, `touggle${index}`)} />
                                                            </div>
                                                        </td>
                                                        {/* <td>
                                                        <div>

                                                            <input className="form-check-input" type="checkbox" checked={customersInfo.isChecked} disabled={customersInfo.status != "Active"} inputId={`selectId${index}`} onClick={() => { handleSelectChangeField(customersInfo, `selectId${index}`); }} />
                                                        </div>
                                                    </td> */}




                                                        {customersInfo.status_name === null || customersInfo.status_name === "null" ?
                                                            <td></td> : <td>{customersInfo.status_name}</td>}

                                                        <td className='text-center action-right-fix-list'>
                                                            <div>

                                                                <input className="form-check-input" type="checkbox" checked={customersInfo.isChecked} inputId={`selectId${index}`} onClick={() => { handleSelectChangeField(customersInfo); }} />
                                                            </div>
                                                        </td>





                                                        {/* <td className='text-center action-right-fix-list'><a className="edit me-2" onClick={() => navigate("/customerEdit", ls.set("AdminId", customersInfo.customer_id))}>Edit</a>
                                                            <a className="edit" onClick={() => navigate("/CustomerView", ls.set("AdminId", customersInfo.customer_id))}>view</a></td> */}
                                                    </tr>)
                                                }
                                                )) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                                    </tbody>
                                </table>
                            </div>
                            <ul className="pagination">
                                <Pagination className=""
                                    totalRecords={totalRecords}
                                    pageLimit={pageLimit}
                                    pageRangeDisplayed={1}
                                    onChangePage={(page) => setCurrentPage(page)}
                                />
                            </ul>
                        </>
                    }



                </div>
            </div>
            {isEdit && <Popup
                isClose={false}
                content={<>
                    <EditManageZipCodePopup toggle={toggleEditManageZipCodePopup} reCallFunction={reCallFunction} />
                </>}
                handleClose={toggleEditManageZipCodePopup}
            />}

            {isSelect && <Popup
                isClose={false}
                content={<>
                    <SelectAllPopup toggle={toggleSelectPopup} reCallFunction={reCallFunction1} selectedValues={selectedValues} PageselectAll={PageselectAll} Status={Status} Filterstatus={Filterstatus1} FilterSelect={FilterSelect} selectedDontCall={PageselectAll == "false" || PageselectAll == "" ? selectedDontCall : allDontCall} />
                </>}
                handleClose={toggleSelectPopup}
            />}

            {IsRecycle && <RecycPopup
                isClose={false}
                content={<>
                    <EditManageRecyclePopup toggle={toggleEditManageRecyclePopup} reCallFunction={reCallFunction} />
                </>}
                handleClose={toggleEditManageRecyclePopup}
            />}
            {IsReassign && <Popup
                isClose={false}
                content={<>
                    <Reassign toggle={toggleEditManageReassignPopup} reCallFunction={reCallFunction1} selectedValues={selectedValues} PageselectAll={PageselectAll} Status={Status} Filterstatus={Filterstatus1} FilterSelect={FilterSelect} selectedDontCall={PageselectAll == "false" || PageselectAll == "" ? selectedDontCall : allDontCall} />
                </>}
                handleClose={toggleEditManageRecyclePopup}
            />}
            {isOpenPopup && (
                < CommonPopup
                    handleClose={togglePopup}
                    popupTitle={popupTitle}
                    popupMsg={popupMsg}
                    popupType={popupType}
                    popupActionType={popupActionType}
                    popupActionValue={popupActionValue}
                />
            )}
        </div>
    );
};

export default ReassignCustomers;