import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import SideBar from '../SideBar/sidebar';
import API from '../../Services/BaseService';
import Loading from '../../Component/Loading/Loading';
import Pagination from 'reactjs-hooks-pagination';
import { useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import CommonPopup from '../../Component/CommonPopup/CommonPopup';
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import ls from 'local-storage';
import moment from 'moment';
import CheckBoxPopup from './CheckBoxPopup';
import Popup from "../../Component/Popup/Popup";


const CallqueueAdd = () => {
    const navigate = useNavigate();
    // const userData = useSelector(state => state.LoginReducer.payload);
    const [PageNn, setPageNo] = useState(10);
    const pageLimit = PageNn;
    const [customersInfo, setCustomersInfo] = useState("");
    console.log("customersInfo4444", customersInfo);
    const [loading, setLoading] = useState(true)
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [operatorId, setOperatorId] = useState("");
    console.log("operatorId", operatorId);
    const [selectedOperatorName, setSelectedOperatorName] = useState("");
    const [operatorName, setOperatorName] = useState([]);
    const [assignDate, setAssignDate] = useState("");
    const [assignDateError, setAssignDateError] = useState("");
    /* const [operatorNameError, setOperatorNameError] = useState("");*/
    const [operatorcheckerror, setOperatorCheckError] = useState("");
    // const [operatorcheckerror1,setOperatorCheckError1] = useState(""); 

    const [customerName, setCustomerName] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [search, setSearch] = useState("");
    /*  const [CustError, setCustError] = useState(""); */
    const [newSelect, setNewSelect] = useState([]);
    console.log("newSelect", newSelect)
    const [, setSelectAll] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);
    console.log("selectedValues6666", selectedValues);
    const [/* Assignstatus */, setAssignstatus] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isSelect, setIsSelect] = useState(false);
    const [page, setPage] = useState("")
    const [selectedPage, setSelectedPage] = useState(false);
    const [custlist, setCustList] = useState("");

    const [select, setSelect] = useState(false);
    const [len, setLen] = useState("")
    const [unCheckLen, setunCheckLen] = useState("")
    console.log("unCheckLen444", unCheckLen);
    const [checkLen, setCheckLen] = useState("")
    console.log("checkLen", checkLen);
    const [flag, setFlag] = useState(false)
    const [CompanyName, setCompanyName] = useState("");

    const [uncheckvalue, setUnCheckValues] = useState("");
    console.log("uncheckvalue55", uncheckvalue);



    //select allpage
    const [PageselectAll, setPageselectAll] = useState(false);
    console.log("PageselectAll", PageselectAll);

    const [FilterSelect, setFilterSelect] = useState([]);

    const [FilterSelectMapid, setFilterSelectMapid] = useState([]);
    const [FilterSelectType, setFilterSelectType] = useState([]);
    const [FilterSelectcompany, setFilterSelectcompany] = useState([]);
    console.log("FilterSelectcompany", FilterSelectcompany);

    console.log(FilterSelect, "FilterSelect");

    let onSelectedData = [];
    const recallfunction = (data) => {

        setIsOpen(data);
    };
    function formatMobileNO(value) {
        var x = value.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
        value = '+1 ' + '(' + x[1] + ') ' + x[2] + '-' + x[3];
        return value;
    }

    useEffect(() => {
        CustomersListInfo();
        //handleOperatorName(customersInfo);
        //handleOperatorName();
        AsssignStatus();
    }, [currentPage, pageLimit])

    const AsssignStatus = () => {

        API.post("AssingmapCustomerlists/condition").then(response => {
            if (response.data.success == true) {
                setAssignstatus(response.data?.data);
            }
        });
    }


    const CustomersListInfo = () => {
        SelectALLStatus()

        let request = {

            customer_name: customerName,
            Phone: phoneNo,
            rowsPerPage: pageLimit,
            pageNumber: currentPage,
            operator_id: !SelectedStatus ? "" : SelectedStatus.operator_id,
            company_id: !SelectedStatus1 ? "" : SelectedStatus1.company_id,
            call_status_id: !SelectedStatus2 ? "" : SelectedStatus2.call_status_id,
            from_date: fromdate,
            to_date: todate,
            data: search === "" ? "" : search
        }

        API.post("callqueuecustomerlistsearch/condition", request).then(response => {
            if (response.data.success == true) {
                setFilterSelect("");
                setFilterSelectMapid("");
                setFilterSelectType("");
                setCustomersInfo(response.data?.data.map((data) => { return { ...data, isChecked: false, currentPage: "" } }));
                setTotalRecords(response.data?.data1[0].count);
                /*  document.getElementById("page").value = "10";
                 setPageNo(10); */
                response.data?.data.map((data, index) => {
                    const touggle = document.getElementById(`touggle${index}`)
                    if (touggle != null) {
                        if (data.status === "Active") {
                            touggle.checked = true;
                        }
                        else {
                            touggle.checked = false;
                        }
                    }
                })
                //setCurrentPage(1);
            }
        })
            .finally(() => {
                setLoading(false);
            });
    }

    /*  after clear list function */
    const CustomersListClear = () => {

        let request = {

            customer_name: "",
            Phone: "",
            rowsPerPage: "10",
            pageNumber: "1",
            operator_id: "",
            company_id: "",
            call_status_id: "",
            from_date: "",
            to_date: "",
            data: "",
        }

        API.post("callqueuecustomerlist/condition", request).then(response => {
            if (response.data.success == true) {

                setCustomersInfo(response.data?.data.map((data) => { return { ...data, isChecked: false, currentPage: "" } }));
                setTotalRecords(response.data?.data1[0].count);

                /*  document.getElementById("page").value = "10";
                 setPageNo(10); */
                response.data?.data.map((data, index) => {
                    const touggle = document.getElementById(`touggle${index}`)
                    if (touggle != null) {
                        if (data.status === "Active") {
                            touggle.checked = true;
                        }
                        else {
                            touggle.checked = false;
                        }
                    }
                })
                //setCurrentPage(1);
            }
        }).finally(() => {
            setLoading(false);
        });

        API.post("callqueuecustomerlistselectall/condition", request).then(response => {
            if (response.data.success == true) {
                setFilterSelect("");
                setFilterSelectMapid("");
                setFilterSelectType("");
                const customerIds = response.data?.data.map(data => data.customer_id);
                const customermapIds = response.data?.data.map(data => data.customermap_id);
                const customertypeIds = response.data?.data.map(data => data.type);
                const customercompanyIds = response.data?.data.map(data => data.company_id);
                setFilterSelect(customerIds);
                console.log("customerIds55555", customerIds);
                setFilterSelectMapid(customermapIds);
                setFilterSelectType(customertypeIds);
                setFilterSelectcompany(customercompanyIds);

            }
        });
    }


    const SelectALLStatus = () => {

        let request = {

            customer_name: customerName,
            Phone: phoneNo,
            rowsPerPage: pageLimit,
            pageNumber: currentPage,
            operator_id: !SelectedStatus ? "" : SelectedStatus.operator_id,
            company_id: !SelectedStatus1 ? "" : SelectedStatus1.company_id,
            call_status_id: !SelectedStatus2 ? "" : SelectedStatus2.call_status_id,
            from_date: fromdate,
            to_date: todate,

            data: search === "" ? "" : search
        }
        console.log(request, "request");

        API.post("callqueuecustomerlistselectall/condition", request).then(response => {
            if (response.data.success == true) {
                setPageselectAll(false);
                setFilterSelect("");
                setFilterSelectMapid("");
                setFilterSelectType("");
                const customerIds = response.data?.data.map(data => data.customer_id);
                const customermapIds = response.data?.data.map(data => data.customermap_id);
                const customertypeIds = response.data?.data.map(data => data.type);
                const customercompanyIds = response.data?.data.map(data => data.company_id);
                setFilterSelect(customerIds);
                console.log("customercompanyIds66", customercompanyIds);
                setFilterSelectMapid(customermapIds);
                setFilterSelectType(customertypeIds);
                setFilterSelectcompany(customercompanyIds);
            }
        });
    }



    useEffect(() => {
        if (currentPage === page && selectedPage === 1) {
            setSelectAll(true)
            setSelect(true)
        }
        else if (currentPage === page) {
            setSelect(true)
        }
        else {
            setSelectAll(false)
            setSelect(false)
        }
    }, [currentPage])

    useEffect(() => {
        if (!flag) {
            setSelect(false)
            console.log("useeffect first if calling");
        } else {
            if ((len !== unCheckLen) && (len !== checkLen)) {
                console.log("useeffect status", len !== unCheckLen);
                setSelect(false)
            } else if (checkLen === len) {
                setSelect(true)
                console.log("useeffect else if calling");
            } else {
                setSelect(false)
                console.log("useeffect else calling");
            }
        }

    }, [unCheckLen, checkLen])

    const handleSelectField = (item, id, flag, crntPage) => {
        setPageselectAll(false);
        setSelect(!select)
        setFlag(flag)

        onSelectedData.map((data) => { data.customer_id })
        const checkbox = document.getElementById(id)
        const filter = newSelect.filter(data => data.crntPage === crntPage);
        setNewSelect(filter.length ? newSelect.filter(item => item.crntPage !== crntPage) : [...newSelect, { crntPage }]);
        if (checkbox != null) {
            if (checkbox.checked === true) {



                console.log("if calling");
                const checkPage = checkbox.checked === true ? 1 : ""

                const checkPage1 = currentPage
                setSelectedPage(checkPage)
                setPage(checkPage1)
                console.log("checkitem checkPage", onSelectedData.map((data) => { return { "customer_id": data.customer_id, "customermap_id": data.customermap_id, "type": data.type, "company_id": data.company_id } }));
                setSelectAll(true)
                setSelect(true)

                const selValues = [...selectedValues, ...onSelectedData.map((data) => { return { "customer_id": data.customer_id, "customermap_id": data.customermap_id, "type": data.type, "company_id": data.company_id } })]
                const filteredArr = selValues.reduce((acc, current) => {
                    const x = acc.find(item => item.customer_id === current.customer_id);
                    if (!x) {
                        return acc.concat([current]);
                    } else {
                        return acc;
                    }
                }, []);
                setSelectedValues(filteredArr)


                for (let i = 0; i < onSelectedData.length; i++) {
                    console.log("findindex.len", onSelectedData.length);
                    setLen(onSelectedData.length);
                    console.log("findindex i", i);
                    const sorting = customersInfo.sort((a, b) => b.customer_id - a.customer_id);
                    const findIndex = sorting.findIndex(item => item.customer_id === onSelectedData[i].customer_id);
                    console.log("findindex", findIndex, sorting);
                    sorting[findIndex].isChecked = true;
                    sorting[findIndex].currentPage = crntPage;
                    setCustomersInfo(sorting);

                }

            }
            else {
                console.log("else calling");
                const tem = selectedValues;
                setSelectAll(false)
                for (let i = 0; i < onSelectedData.length; i++) {
                    for (let j = 0; j < tem.length; j++) {
                        if (tem[j].customer_id === onSelectedData[i].customer_id) {
                            tem.splice(j, 1);
                        }
                    }
                }
                for (let i = 0; i < onSelectedData.length; i++) {
                    console.log("findindex.len", onSelectedData.length);
                    setLen(onSelectedData.length);
                    console.log("findindex i", i);
                    const sorting = customersInfo.sort((a, b) => b.customer_id - a.customer_id);
                    const findIndex = sorting.findIndex(item => item.currentPage === onSelectedData[i].currentPage);
                    console.log("findindex", findIndex, sorting);
                    sorting[findIndex].isChecked = false;
                    sorting[findIndex].currentPage = '';
                    setCustomersInfo(sorting);
                }

                handleOperatorName(selectedValues.filter(data => data.company_id !== data.company_id));


                // setSelectedCompanyIds(prev => [...prev, items.company_id]);

                console.log("hello");
            }




        }

        handleOperatorName(selectedValues);
        if (!areCompanyIdsSame(selectedValues) || !areCompanyIdsDifferent(customersInfo)) {
            setOperatorCheckError(" Selected Different company_names");

        }




    };

    const handleSelectChangeField = (items, crntPage) => {

        setPageselectAll(false);
        console.log("items", items);
        console.log("check the current pages", onSelectedData);


        const uncheckstatus = selectedValues.filter(data => data.customer_id !== items.customer_id);








        console.log("customersInfolenght", uncheckstatus.length, customersInfo.length);
        setunCheckLen(uncheckstatus.length)
        if (checkLen !== onSelectedData.length) {
            document.getElementById("SelectAll").checked = false;
            setSelect(false)
            console.log("single uncheck", uncheckstatus.length, onSelectedData.length, "checkLen", checkLen);


        }

        const checkval = selectedValues.filter((data) => data.customer_id === items.customer_id);

        if (checkval.length > 0) {
            console.log("if check calling");
            const remove = customersInfo.filter(data => data.customer_id === items.customer_id);
            setNewSelect(newSelect.filter(item => item.crntPage !== remove[0].currentPage));
            const allList = customersInfo;
            console.log("selectedValues", selectedValues);
            const findIndex = allList.findIndex((data) => data.customer_id === items.customer_id);
            allList[findIndex].isChecked = false;
            allList[findIndex].currentPage = '';
            console.log("findIndex if", findIndex);
            setCustomersInfo(allList);
            setSelectedValues(selectedValues.filter(data => data.customer_id !== items.customer_id));
            const value = selectedValues.filter(data => data.customer_id !== items.customer_id).length;
            const value1 = selectedValues.filter(data => data.customer_id !== items.customer_id);
            setUnCheckValues(value1);
            console.log("value1", value1);
            setCheckLen(value.length);
            console.log("single selectedValues if", selectedValues.filter(data => data.customer_id !== items.customer_id).length);



            handleOperatorName(selectedValues.filter(data => data.company_id !== items.company_id));




        }
        else {
            const allList = customersInfo;
            const findIndex = allList.findIndex((data) => data.customer_id === items.customer_id);
            allList[findIndex].isChecked = true;
            allList[findIndex].currentPage = crntPage;
            setCustomersInfo(allList);
            setSelectedValues([...selectedValues, { "customer_id": items.customer_id, "customermap_id": items.customermap_id, "type": items.type, "company_id": items.company_id }])
            const value = [...selectedValues, { "customer_id": items.customer_id, "customermap_id": items.customermap_id, "type": items.type, "company_id": items.company_id }]
            setCheckLen(value.length);
            const sellectAllElement = onSelectedData.filter(data => data.isChecked === true)

            if (sellectAllElement.length === onSelectedData.length && crntPage === currentPage) {
                setNewSelect([...newSelect, { crntPage }]);
            }

            console.log("sellectAllElement", sellectAllElement);
            handleOperatorName(sellectAllElement);
            if (!areCompanyIdsSame(selectedValues) || !areCompanyIdsDifferent(customersInfo)) {
                setOperatorCheckError("Selected Different company_names");

            }

        }




    };




    //add
    const SelectCheckBoxPopup = () => {
        /*  setCustError(""); */
        setAssignDateError("");
        /*  setOperatorNameError("");
  
          /*   if (selectedValues.length === 0) {
                setCustError("Customer Name is required");
                return;
            } */


        {/*
        if (!operatorId) {
            setOperatorNameError("Operator Name is required");
            return;
        }

          */}



        if (!assignDate) {
            setAssignDateError("Assign Date is required");
            return;
        }
        toggleSelectPopup();

    }

    const toggleSelectPopup = () => {
        setIsSelect(!isSelect);
    }

    // const handlereCallFunction = () => {
    //     setOperatorId("");
    //     setSelectedOperatorName("");
    //     setAssignDate("");
    //     CustomersListInfo();
    //     document.getElementById("page").value = "10";
    //     setSelectedValues([])
    //     setNewSelect([]);
    //     for (let i = 0; i < customersInfo.length; i++) {
    //         const sorting = customersInfo;
    //         sorting[i].isChecked = false;
    //         sorting[i].currentPage = '';
    //         setCustomersInfo(sorting);
    //     }
    //     // CustomersListInfo();
    // }


    const redirection = (id) => {

        navigate({ pathname: "/CustomerView" });
        ls.set("AdminId", id);
        console.log("AdminId", id);
    };





    const handleAllPageSelectField = (PageselectAll) => {
        // setStatusError("");
        setPageselectAll(false);

        const updatedCustomersInfo = customersInfo.map((customer) => ({
            ...customer,


            isChecked: false,

        }));




        setFlag("");
        // setStatusError("");
        setNewSelect([]);
        setSelectedPage("");
        setPage("");
        setSelectedValues([]);
        setLen(0);

        setCustomersInfo(updatedCustomersInfo);
        // console.log("updatedCustomersInfo",updatedCustomersInfo);
        handleOperatorName(updatedCustomersInfo);
        if (!areCompanyIdsSame(selectedValues) || !areCompanyIdsDifferent(customersInfo)) {
            setOperatorCheckError("Selected Different company_names");

        }


        setPageselectAll(!PageselectAll);

    };





    const areCompanyIdsSame = (selectedItems) => {
        const companyIds = selectedItems.map(item => item.company_id);
        const uniqueCompanyIds = [...new Set(companyIds)];
        return uniqueCompanyIds.length === 1;
        // return companyIds.length === 1;  
    };




    const areCompanyIdsDifferent = (selectedItems) => {
        const companyId = selectedItems.map(item => item.company_id);
        const uniqueCompanyId = [...new Set(companyId)];
        return uniqueCompanyId.length === 1;
        //return companyId.length ===1 ;  
    };













    // Check if the selected items have exactly two different company IDs

    {/*
    const areCompanyIdsDifferent = (selectedItems) => {
        const companyId = selectedItems.map(item => item.company_id);
        const uniqueCompanyId = [...new Set(companyId)];
        return uniqueCompanyId.length === 2;  // True if there are exactly two unique company IDs
    };

   */}









    const handleOperatorName = (sellectAllElement) => {

        const companyId = customersInfo.map(item => item.company_id);
        const comapanyIds = sellectAllElement.map(item => item.company_id);


        //const companyId = customersInfo.filter(item => item.isChecked).map(item => item.company_id);
        //const comapanyIds = sellectAllElement.filter(item => item.isChecked).map(item => item.company_id);

        const uniqueCompanyIds = [...new Set(comapanyIds)];
        const uniqueCompanyId = [...new Set(companyId)];



        {/*
       
        if (uniqueCompanyIds.length > 1 || uniqueCompanyId.length > 1 ) {
            
            setOperatorCheckError("Check Box have same company name required");
            return;  
        }

        console.log('uniqueCompanyIds:', uniqueCompanyIds);
console.log('uniqueCompanyId:', uniqueCompanyId);
    
       
        setOperatorCheckError("");

      */}














        if (sellectAllElement.length > 0) {
            let req = {
                company_id: uniqueCompanyIds
            };
            API.post("mapOperatorlist/condition", req).then(response => {
                setOperatorName(response.data?.data);
            }).catch(error => {
                console.error("API request failed:", error);
            });
        }


        else {

            let req = {
                company_id: uniqueCompanyId
            };




            API.post("mapOperatorlist/condition", req).then(response => {
                setOperatorName(response.data?.data);
            }).catch(error => {
                console.error("API request failed:", error);
            });


        }



        // if (uniqueCompanyIds.length > 1  ) {
        //    setOperatorCheckError("Please select only one company name.");
        //    return; // Stop further execution if validation fails
        // }



        // If all selected items have the same company ID, show an error




        //  setOperatorCheckError1("");










    };










    const handleOperatorNameId = (e) => {
        setSelectList("")
        setOperatorId(e.target.value);
        const selectedLangDetails = operatorName.filter(data => data.operator_id == e.target.value)[0]
        setSelectedOperatorName(selectedLangDetails);
        Listservice(operatorName.filter(data => data.operator_id == e.target.value)[0])

    }

    const handleclear = () => {
        setCustomerName("");
        // setoperator("");
        setPhoneNo("");
        // setfromdate("");
        // settodate("");
        // setFromDateError("");
        setSelectedOperatorName("");
        setOperatorId("");
        //setStatusValue("");
        setCompnayname("");
        setCustList("");
        setSelectList("");
        setSelectedStatus1("")
        setSelectedStatus2("")
        setSelectedStatus();
        // setOperatorName("");
        // setStatus("");


        setAssignDate("");


        setfromdate("");
        settodate("");

        setLoading(true);
        setOperatorLIst();
        operatorLists();
        // handleOperatorName();
        //callstatusservice();
        companyName();

        CustomersListClear();




        console.log({
            customerName: customerName,
            phoneNo: phoneNo,
            selectedOperatorName: selectedOperatorName,

        });

        // document.getElementById("page").value = "10";
        // document.getElementById("userName").value = "Select userName";
        setSelectedValues([])
        setFilterSelect("");
        setPageselectAll(false);

        setNewSelect([]);
        for (let i = 0; i < customersInfo.length; i++) {
            const sorting = customersInfo;
            sorting[i].isChecked = false;
            sorting[i].currentPage = '';
            setCustomersInfo(sorting);
        }

    }

    const onKeydownAccountSearch = (event) => {
        if (event.key === "Enter") {
            CustomerSearch();
        }
    };

    const onSearch = (e) => {
        setSearch(e.target.value);
    }

    const CustomerSearch = () => {


        SelectALLStatus()

        let request = {
            data: search === "" ? "" : search,
            rowsPerPage: pageLimit,
            pageNumber: currentPage,
            customer_name: customerName,
            company_id: !SelectedStatus1 ? "" : SelectedStatus1.company_id,
            operator_id: !SelectedStatus ? "" : SelectedStatus.operator_id,
            call_status_id: !SelectedStatus2 ? "" : SelectedStatus2.call_status_id,
            from_date: fromdate,
            to_date: todate,
            Phone: phoneNo,
        }

        API.post("callqueuecustomerlistsearch/condition", request).then(response => {
            setPageselectAll(false);
            setCustomersInfo(response.data?.data.map((data) => { return { ...data, isChecked: false, currentPage: "" } }));

            setTotalRecords(response.data?.data1[0].count);
            /* setCurrentPage(1); */
        });
    }

    useEffect(() => {
        if (search != "") {
            const timer = setTimeout(() => {
                CustomerSearch();
            }, 200);
            return () => {
                clearTimeout(timer);
            };
        } else {
            setLoading(true);
            CustomersListInfo();
        }
    }, [search]);

    const customerFilter = () => {
        SelectALLStatus()
        let request = {
            customer_name: customerName,
            Phone: phoneNo,
            rowsPerPage: pageLimit,
            pageNumber: currentPage,
            operator_id: !SelectedStatus ? "" : SelectedStatus.operator_id,
            company_id: !SelectedStatus1 ? "" : SelectedStatus1.company_id,
            call_status_id: !SelectedStatus2 ? "" : SelectedStatus2.call_status_id,
            from_date: fromdate,
            to_date: todate,
            data: search === "" ? "" : search
        }

        API.post("callqueuecustomerlistsearch/condition", request).then(response => {
            setPageselectAll(false);
            setCustomersInfo(response.data?.data.map((data) => { return { ...data, isChecked: false, currentPage: "" } }));
            setTotalRecords(response.data?.data1[0].count);
            /*  document.getElementById("page").value = "10";
             setCurrentPage(1);
             setPageNo(10); */

        })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleSearch = () => {
        /*setOperatorNameError("");*/
        setAssignDateError("");
        customerFilter();
    }

    const getclear = () => {
        /*setOperatorNameError("");*/
        setAssignDateError("");
    }
    useEffect(() => {
        if (selectedValues.length == 0) {
            getclear()
        }
    }, [selectedValues]);

    const inputPropsdate = {
        placeholder: "DD/MM/YYYY",
        value: assignDate
    };

    const yesterday = moment().subtract(1, 'day');
    const disablePastDate = current => {
        return current.isAfter(yesterday);
    };
    const registrationDate = (event) => {
        if (event._isAMomentObject == true) {
            setAssignDate(event.format("YYYY-MM-DD"));
        }
    }



    const [operatorlist, setOperatorLIst] = useState("")

    const operatorLists = (data) => {
        let request = {
            company_id: !data ? "" : data.company_id,
        }
        API.post('operatorbycompanyid/condition', request).then((response) => {
            if (response.data.success == true) {
                setOperatorLIst(response.data.data);

            }
        });
    }

    useEffect(() => {
        operatorLists()

    }, [])

    const [/*Status */, setStatus] = useState("");
    const [SelectedStatus, setSelectedStatus] = useState("");


    const handlelist = (e) => {
        setStatus(e.target.value);

        // operatorlist.filter((data)=> data.operator_id == e.target.value)[0])
        setSelectedStatus(operatorlist.filter((data) => data.operator_id == e.target.value)[0]);





    }

    const [comanyname, setCompnayname] = useState("")


    const [SelectedStatus1, setSelectedStatus1] = useState("");

    const handleCompanyName = (e) => {
        setCompnayname(e.target.value);
        setSelectedStatus1(
            CompanyName.filter((data) => data.company_id == e.target.value)[0])
        operatorLists(CompanyName.filter((data) => data.company_id == e.target.value)[0]);
    }


    // const [callstatus,setCallStatus] = useState("")
    // const [statusvalue,setStatusValue] = useState("");
    const [SelectedStatus2, setSelectedStatus2] = useState("")


    {/*
    const handleCallStatus = (e)=>{
        setStatusValue(e.target.value);
        setSelectedStatus2(callstatus.filter((data)=>data.call_status_id == e.target.value)[0])

                }

           */ }




    const companyName = () => {

        API.post("companyListview/condition").then(response => {
            setCompanyName(response.data.data);
        });
    }

    {/*
    const callstatusservice = ()=>{
        API.post("notAssignCallStatus/condition").then(response=>{
            setCallStatus(response.data.data)


        })
    }
   */}



    useEffect(() => {
        companyName();


    }, [])


    const [fromdate, setfromdate] = useState("");
    const [todate, settodate] = useState("");

    const inputProps = {
        placeholder: "From Date",
        value: fromdate

    }

    const inputProps1 = {

        placeholder: "To Date",
        value: todate
    }

    const fromDate = (event) => {
        if (event._isAMomentObject == true) {
            setfromdate(event.format("YYYY-MM-DD"))
            todate("");


        }

    }


    const toDate = (event) => {
        if (event._isAMomentObject == true) {
            settodate(event.format("YYYY-MM-DD"))

        }

    }



    function formatTime(value) {
        if (!value) {
            return value;
        }

        var dateLocal = new Date(value);
        if (isNaN(dateLocal.getTime())) {
            return "Invalid Date";
        }

        // Correct for timezone offset if necessary
        var newDate = new Date(dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000);

        // Format the date without adding "at" or any extra text
        var formattedDate = newDate.toLocaleString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
        });


        return formattedDate.replace(/\s*at\s*/, ',');
    }

    const [selectlist, setSelectList] = useState("");
console.log("selectlist",selectlist);

    const handleListId = (e) => {
        setSelectList(
            custlist.filter((data) => data.customer_list_id == e.target.value)[0]
        );
    }

    const Listservice = (data) => {

        let request = {
            operator_id: !data ? "" : data.operator_id,
        }


        API.post('customerlistassgin/condition', request).then((response) => {
            if (response.data.success == true) {

                setCustList(response.data.data);

            }
        });


    }

    return (

        <div className='wrapper'>
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content addcallqueue">
                    <div className="back-heading">
                        {/* <div className="back-btn" onClick={() => navigate('/Callqueue')}><i className="fa-sharp fa-solid fa-arrow-left me-1"></i>Back</div> */}

                        {/* <h5 className="heading-text">Add New Call Queue</h5> */}

                        <h5 className="heading-text">Not Assign Customers</h5>


                    </div>

                    {/*
                    
                    <div className="row">
                    <div className="col">
        
                   <div className="add-bt"  onClick={() => navigate("/Callqueue")}>Assigned Customer</div>
                   <div className="add-bt1" onClick={() => navigate("")}>Not Assigned Customer</div>
                        </div>
                        </div>

                    */}




                    <div className="row">
                        <div className="col-xl-10">
                            <div className="common-heading-sec">

                                {/*
                             <div className=" search-bar"   >
                             <select
  className="form-select"
  aria-label="Default select example"
  id="Sale"
  onChange={handleCallStatus}
  value={statusvalue} 
>
  <option style={{ display: "none" }}>Call Status</option>
  {callstatus?.length > 0 && (
    <>
      {callstatus.map((item) => (
        <option
          key={item.call_status_id}
          value={item.call_status_id}
        >
          {item.status_name}
        </option>
      ))}
    </>
  )}
</select>
                             </div>

                                  */}




                                <div className="search-bar">
                                    <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        id="Sale"
                                        onChange={handleCompanyName}
                                        value={comanyname}
                                    >
                                        <option style={{ display: "none" }} >Company Name</option>
                                        {CompanyName?.length > 0 && (
                                            <>
                                                {CompanyName.map((CompanyName) => (
                                                    <option
                                                        key={CompanyName.
                                                            company_id
                                                        }
                                                        value={CompanyName.
                                                            company_id
                                                        }
                                                    >
                                                        {CompanyName.company_name}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                    </select>
                                </div>


                                <div className=" search-bar">
                                    <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        id="Sale"
                                        onChange={handlelist}
                                    // value={Status}
                                    >
                                        <option style={{ display: "none" }} >Operator Name</option>
                                        {operatorlist?.length > 0 && (
                                            <>
                                                {operatorlist.map((operatorlist) => (
                                                    <option
                                                        key={operatorlist.
                                                            operator_id}
                                                        value={operatorlist.
                                                            operator_id}
                                                    >
                                                        {operatorlist.operatorname}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                    </select>
                                </div>





                                <div className=" search-bar">
                                    <input type="text" className="form-control" value={customerName} placeholder="Customer Name" onChange={(e) => setCustomerName(e.target.value)} />
                                </div>
                                {/* <div className="search-bar">
                                    <input type="text" className="form-control" value={operator} placeholder="Operator" onChange={(e) => setoperator(e.target.value)} />
                                </div> */}
                                <div className="search-bar">
                                    <input type="text" className="form-control" value={phoneNo} placeholder="Phone#" onChange={(e) => setPhoneNo(e.target.value.replace(/\D/g, ""))} />
                                </div>


                                <div className='col-xl-2' style={{ display: 'none' }}>
                                    <div className='add-section'>
                                        <div className="search-bar">
                                            <input type="search" className="form-control" placeholder="Name  or Phone#" value={search} onKeyDown={onKeydownAccountSearch} onChange={onSearch} />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="datePickerBlock">
                                   
                                    <Datetime inputProps={inputProps} timeFormat={false} dateFormat="YYYY-MM-DD"
                                        name="Date" onChange={fromDate} closeOnSelect={true}
                                        id="meeting_date" />
                                </div>
                                <div className="datePickerBlock">
                                    <Datetime inputProps={inputProps1} timeFormat={false} dateFormat="YYYY-MM-DD"
                                        name="Date" isValidDate={disablePastDt} onChange={toDate} closeOnSelect={true}
                                        id="meeting_date" />
                                    <div className="error-msgs">
                                        <p className="form-input-error" >{fromDateError}</p>
                                    </div>
                                </div> */}

                                {/*
                                <div className="search-clear" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass"></i>Find</div>
                                <div className="search-clear" onClick={handleclear}><i className="fa-solid fa-ban"></i>Clear</div>
                                  */ }
                            </div>
                        </div>
                        {/*
                        <div className='col-xl-2'>
                            <div className='add-section'>
                                <div className="search-bar">
                                    <input type="search" className="form-control" placeholder="Name  or Phone#" value={search} onKeyDown={onKeydownAccountSearch} onChange={onSearch} />
                                </div>
                            </div>
                        </div>
                       */}

                    </div>
                    <div className='dateandtime'>
                        <div className='row mt-2 '>

                            <div className='fromdate datePickerBlock'>
                                <Datetime inputProps={inputProps} timeFormat={false}
                                    dateFormat="YYYY-MM-DD" onChange={fromDate} closeOnSelect={true}
                                />
                            </div>
                            <div className='todates datePickerBlock'>
                                <Datetime inputProps={inputProps1} timeFormat={false}
                                    dateFormat="YYYY-MM-DD" onChange={toDate} closeOnSelect={true}
                                />
                            </div>
                            <div className="search-clear collg1" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass"></i>Find</div>
                            <div className="search-clear collg1" onClick={handleclear}><i className="fa-solid fa-ban"></i>Clear</div>
                            <div className='col-lg-6 dateandtime'>
                                <div className="page-filter">
                                    <span className="row-per-pages">Rows per page:</span>
                                    <div>
                                        <select name="page" id="page" onChange={(e) => setPageNo(e.target.value)}>
                                            <option value="10">10</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                            <option value="500">500</option>
                                        </select>
                                    </div>
                                    <div className='PageselectAll'>
                                        <span> Select All Pages</span>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={PageselectAll}

                                            onChange={() => { handleAllPageSelectField(PageselectAll) }} />
                                    </div>
                                    <div className="count">
                                        Count:<span>{totalRecords}</span>
                                    </div>
                                </div>
                            </div>

                            {(selectedValues?.length > 0 || PageselectAll) && customersInfo?.length > 0 ?
                                <>
                                    <div className='col-lg-6 assign-cus '>
                                        <div className="col-lg-5 mb-12 me-1">
                                            <label htmlFor="operatorName" className="col-form-label">
                                                Operator Name <span className='mandatory ms-1'>*</span>
                                            </label>
                                            <select className="form-select" id="operatorName" onChange={handleOperatorNameId}>
                                                <>
                                                    <option style={{ display: "none" }}>Select OperatorName</option>
                                                    {areCompanyIdsSame(selectedValues) || areCompanyIdsDifferent(customersInfo) ? (
                                                        <>
                                                            {operatorName?.length > 0 && (
                                                                operatorName.map((data) => (
                                                                    <option key={data.operator_id} value={data.operator_id}>
                                                                        {data.operator_name}
                                                                    </option>
                                                                ))
                                                            )}
                                                        </>
                                                    ) : null}
                                                </>
                                            </select>
                                            {/*<p className="form-input-error">{operatorNameError}</p> */}
                                            {areCompanyIdsSame(selectedValues) || areCompanyIdsDifferent(customersInfo) ? ("") : (
                                                <p className="form-input-error">{operatorcheckerror}</p>)
                                            }
                                            {/* <p className="form-input-error">{operatorcheckerror1}</p>  */}
                                        </div>

                                        <div className="col-lg-3 mb-12 me-1">
                                            <label htmlFor="listname" className="col-form-label">
                                                List Name 
                                            </label>
                                            <select className="form-select" id="listname" onChange={handleListId}>
                                                <>
                                                    <option style={{ display: "none", fontSize: "18px" }}>--SELECT--</option>
                                                    <option >--SELECT--</option>
                                                    <>
                                                        {custlist?.length > 0 && (
                                                            <>
                                                                {custlist.map((custlist) => (
                                                                    <option
                                                                        key={custlist.customer_list_id}
                                                                        value={custlist.customer_list_id}
                                                                    >
                                                                        {custlist.list_name}
                                                                    </option>
                                                                ))}
                                                            </>
                                                        )}
                                                    </>

                                                </>
                                            </select>
                                        </div>


                                        {/* <div className="col-lg-3 mb-12">
                                            <label htmlFor="City" className="form-label font">List Name</label>
                                            <select className='form-select' aria-label="Default select example" id='Sale' onChange={handleListId} >
                                                <option style={{ display: "none", fontSize: "18px" }}>--SELECT--</option>
                                                <option >--SELECT--</option>
                                                {custlist?.length > 0 && (
                                                    <>
                                                        {custlist.map((custlist) => (
                                                            <option
                                                                key={custlist.customer_list_id}
                                                                value={custlist.customer_list_id}
                                                            >
                                                                {custlist.list_name}
                                                            </option>
                                                        ))}
                                                    </>
                                                )}
                                            </select>
                                        </div> */}
                                        <div className="col-lg-4 mb-12 form-group datePickerBlock ">
                                            <div className="tbox">
                                                <div className="textbox">
                                                    <label htmlFor="assignDate" className="col-form-label">
                                                        AssignDate <span className='mandatory ms-1'>*</span>
                                                    </label>
                                                    <Datetime inputProps={inputPropsdate}
                                                        closeOnSelect={true}
                                                        timeFormat={false}
                                                        dateFormat="DD/MM/YYYY"
                                                        name="Date"
                                                        id="meeting_date"
                                                        isValidDate={disablePastDate}
                                                        onChange={registrationDate}
                                                    />
                                                    <p className="form-input-error">{assignDateError}</p>
                                                </div>
                                            </div>
                                        </div>

                                        {areCompanyIdsSame(selectedValues) || areCompanyIdsDifferent(customersInfo) ? (

                                            <div className="col-lg-2 mb-12 mt-1">
                                                <div className="add-btn submit" onClick={() => SelectCheckBoxPopup()}>Submit</div>
                                            </div>

                                        ) : <div className="col-lg-2 mb-12 mt-1">
                                            <div className="add-btn submit disabled">Submit</div>
                                        </div>}
                                    </div>
                                </>
                                : <> </>}

                        </div>


                    </div>

                    {loading ? <Loading /> :
                        <>
                            <div className="rounded table-responsive">
                                <table className="table bg-white">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Customer Name</th>
                                            {/* <th>Email Id</th> */}
                                            <th>Phone No</th>
                                            {/* <th>Operator Name</th> */}
                                            <th>OperatorName</th>
                                            <th>Date&Time</th>
                                            <th>CompanyName</th>
                                            <th>StatusName</th>
                                            <th>Vehicle</th>
                                            <th>Color</th>
                                            <th>Stock</th>
                                            <th>Source</th>
                                            <th>Comment</th>
                                            {/* <th>Date & Time</th> */}
                                            {/* <th>Assign Status</th> */}
                                            <th className='text-center action-right-fix-list'>SelectAll <input className="form-check-input" type="checkbox"
                                                checked={newSelect.find((newSelect) => {
                                                    return newSelect.crntPage === currentPage
                                                }) ? true : false} id='SelectAll'
                                                onClick={() => { handleSelectField(customersInfo, "SelectAll", true, currentPage) }} /></th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        {customersInfo.length > 0 ? (
                                            customersInfo.sort((a, b) => b.customersInfo - a.customersInfo)
                                                // .slice((currentPage) * pageLimit, currentPage * pageLimit)
                                                .map((customersInfo, index) => {
                                                    onSelectedData = [...onSelectedData, customersInfo]
                                                    return (<tr key={index}>
                                                        <td><a onClick={() => redirection(customersInfo.customer_id)}>{customersInfo.customer_id}</a></td>
                                                        <td><a onClick={() => redirection(customersInfo.customer_id)}>{customersInfo.customername}</a></td>
                                                        {/* {customersInfo.email === null || customersInfo.email === "null" ? <td></td> : <td>{customersInfo.email}</td>} */}
                                                        <td>{formatMobileNO(customersInfo.Phone)}</td>
                                                        <td>{customersInfo.operatorname}</td>
                                                        {/* <td>{formatTime(customersInfo.createdtimes)}</td> */}
                                                        <td>{formatTime(customersInfo.createdAt)}</td>
                                                        <td>{customersInfo.company_name}</td>
                                                        <td>{customersInfo.status_name}</td>

                                                        {/* <td><a onClick={() => redirectionoperator(customersInfo.operator_id)}>{customersInfo.operatorname}</a></td> */}
                                                        {customersInfo.vehicle === null || customersInfo.vehicle === "null" ? <td></td> : <td>{customersInfo.vehicle}</td>}
                                                        {customersInfo.color === null || customersInfo.color === "null" ? <td></td> : <td>{customersInfo.color}</td>}
                                                        {customersInfo.stock === null || customersInfo.stock === "null" ? <td></td> : <td>{customersInfo.stock}</td>}
                                                        {customersInfo.source === null || customersInfo.source === "null" ? <td></td> : <td>{customersInfo.source}</td>}
                                                        {customersInfo.comments === null || customersInfo.comments === "null" ? <td></td> : <td>{customersInfo.comments}</td>}
                                                        {/* <td>{customersInfo.assigndatetime}</td> */}
                                                        {/* {Assignstatus?.find((value) => {
                                                        console.log("value.customer_id", value.customer_id);
                                                        return value.customer_id === customersInfo.customer_id
                                                    })
                                                        ? <td>Assign</td> : <td> Not Assign</td>} */}

                                                        <td className='text-center action-right-fix-list'>
                                                            <div>

                                                                <input className="form-check-input" type="checkbox" checked={customersInfo.isChecked}/*  disabled={customersInfo.status != "Active"} */ inputId={`selectId${index}`} onClick={() => { handleSelectChangeField(customersInfo, currentPage); }} />
                                                            </div>
                                                        </td>
                                                    </tr>)
                                                }
                                                )) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                                    </tbody>
                                </table>
                            </div>
                            <ul className="pagination">
                                <Pagination className=""
                                    totalRecords={totalRecords}
                                    pageLimit={pageLimit}
                                    pageRangeDisplayed={1}
                                    onChangePage={setCurrentPage}
                                />
                            </ul>

                        </>
                    }


                </div>
            </div>

            {isSelect && <Popup
                isClose={false}
                content={<>
                    <CheckBoxPopup toggle={toggleSelectPopup} list={selectlist} reCallFunction={handleclear} selectedValues={selectedValues} PageselectAll={PageselectAll} assignDate={assignDate} selectedOperatorName={selectedOperatorName} FilterSelect={FilterSelect} FilterMapid={FilterSelectMapid} FilterSelectType={FilterSelectType} Filtercompany={FilterSelectcompany}
                    />
                </>}
                handleClose={toggleSelectPopup}
            />}

            {/* {isOpenPopup && (
                <CommonPopup
                    handleClose={togglePopup}
                    popupTitle={popupTitle}
                    popupMsg={popupMsg}
                    popupType={popupType}
                    popupActionType={popupActionType}
                    popupActionValue={popupActionValue}
                    popupActionPath={popupActionPath}
                />
            )} */}
        </div>
    );
};

export default CallqueueAdd;