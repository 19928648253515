import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React from 'react';
import Login from '../Container/Login/Login';
import DashBoard from '../Container//DashBoard/DashBoard';
import Customers from "../Container/Customers/Customers";
import Admin from "../Container/Admin/Admin";
import Operator from "../Container/Operator/Operator";
import Calls from "../Container/Calls/Calls";
import Reports from "../Container/Reports/Reports";
import OperatorAdd from "../Container/Operator/OperatorAdd";
import AdminAdd from "../Container/Admin/AdminAdd";
import CustomersAdd from "../Container/Customers/Customersadd";
import AdminEdit from "../Container/Admin/AdminEdit";
import AdminView from "../Container/Admin/AdminView";
import CustomerEdit from "../Container/Customers/CustomersEdit";
import OperatorEdit from "../Container/Operator/OperatorEdit";
import CustomersMap from "../Container/CustomersMap/CustomersMap";
import CustomersMapAdd from "../Container/CustomersMap/CustomersMapAdd";
import CustomersMapEdit from "../Container/CustomersMap/CustomersMapEdit";
import History from "../Container/History/History";
import CustomerView from "../Container/Customers/CustomerView";
import OperatorView from "../Container/Operator/OperatorView";
import AssignCustomer from "../Container/AssignCustomer/AssignCustomer";
import Reschedule from "../Container/Reschedule/reschedule";
import PrivateRoute from "../utils/PrivateRoutes";
import Callqueue from "../Container/Callqueue/callqueue";
import CallqueueView from "../Container/Callqueue/callqueueView";
import RescheduleView from "../Container/Reschedule/rescheduleView";
import RescheduleEdit from "../Container/Reschedule/rescheduleEdit";
import CallqueueEdit from "../Container/Callqueue/callqueueEdit";
import CallqueueAdd from "../Container/Callqueue/callqueueAdd";
import OperatorActivity from "../Container/OperatorActivity/OperatorActivity";
import Config from "../Container/WebphoneConfig/Config";
import ConfigAdd from "../Container/WebphoneConfig/ConfigAdd";
import ConfigEdit from "../Container/WebphoneConfig/ConfidEdit";
import ConfigView from "../Container/WebphoneConfig/ConfigView";
import CustomerCountPage from "../Container/Assigncutomercountpage/CustomerCountPage";
import CustomerCountPageAdd from "../Container/Assigncutomercountpage/CustomerCountPageAdd";
import CusmoterCountPageEdit from "../Container/Assigncutomercountpage/CusmoterCountPageEdit";
import ConfigAssignAdd from "../Container/WebphoneConfig/ConfigAssignAdd";
import AssignWebphoneConfigList from "../Container/AssignWebphoneConfig/AssignWebphoneConfigList";
import AssignWebphoneConfigAdd from "../Container/AssignWebphoneConfig/AssignWebphoneConfigAdd";
import AssignWebphoneConfigEdit from "../Container/AssignWebphoneConfig/AssignWebphoneConfigEdit";
import AssignWebphoneConfigView from "../Container/AssignWebphoneConfig/AssignWebphoneConfigView";
import CompanyList from "../Container/Company/CompanyList";
import CompanyAdd from "../Container/Company/CompanyAdd";
import CompanyEdit from "../Container/Company/CompanyEdit";
import CompanyView from "../Container/Company/CompanyView";
import Uploadxl from "../Container/Customers/Uploadxl";
import Recycle from "../Container/Customers/Recycle";

import ListName from "../Container/ListName/ListName";
import ListAdd from "../Container/ListName/ListAdd";
import ListEdit from "../Container/ListName/ListEdit";
import ListView from "../Container/ListName/ListView";

import AssignList from "../Container/AssignList/AssignList";
import AssignAdd from "../Container/AssignList/AssignAdd";
import AssignEdit from "../Container/AssignList/AssignEdit";
import AssignView from "../Container/AssignList/AssignView";
import ReassignCustomers from "../Container/Customers/ReassignCustomers";


function AppRouter() {

    return (
        
        <Router>
            <Routes>

                <Route element={<PrivateRoute />}>
                    <Route path="/dashboard" element={<DashBoard />} />
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/customers" element={<Customers />} />
                    <Route path="/reassigncustomers" element={<ReassignCustomers />} />
                    <Route path="/operator" element={<Operator />} />
                    <Route path="/calls" element={<Calls />} />
                    <Route path="/Reports" element={<Reports />} />
                    <Route path="/customersMap" element={<CustomersMap />} />
                    <Route path="/operatorAdd" element={<OperatorAdd />} />
                    <Route path="/adminAdd" element={<AdminAdd />} />
                    <Route path="/customersAdd" element={<CustomersAdd />} />
                    <Route path="/customersMapAdd" element={<CustomersMapAdd />} />
                    <Route path="/adminEdit" element={<AdminEdit />} />
                    <Route path="/customerEdit" element={<CustomerEdit />} />
                    <Route path="/operatorEdit" element={<OperatorEdit />} />
                    <Route path="/customersMapEdit" element={<CustomersMapEdit />} />
                    <Route path="/history" element={<History />} />
                    <Route path="/AdminView" element={<AdminView />} />
                    <Route path="/CustomerView" element={<CustomerView />} />
                    <Route path="/OperatorView" element={<OperatorView />} />
                    <Route path="/AssignCustomer" element={<AssignCustomer />} />
                    <Route path="/Callqueue" element={<Callqueue />} />
                    <Route path="/CallqueueView" element={<CallqueueView />} />
                    <Route path="/CallqueueEdit" element={<CallqueueEdit />} />
                    <Route path="/Reschedule" element={<Reschedule />} />
                    <Route path="/RescheduleView" element={<RescheduleView />} />
                    <Route path="/RescheduleEdit" element={<RescheduleEdit />} />
                    <Route path="/CallqueueAdd" element={<CallqueueAdd />} />
                    <Route path="/OperatorActivity" element={<OperatorActivity />} />
                    <Route path="/Config" element={<Config />} />
                    <Route path="/ConfigAdd" element={<ConfigAdd />} />
                    <Route path="/ConfigEdit" element={<ConfigEdit />} />
                    <Route path="/ConfigView" element={<ConfigView />} />
                    <Route path="/CustomerCountPage" element={<CustomerCountPage />} />
                    <Route path="/CustomerCountPageAdd" element={<CustomerCountPageAdd />} />
                    <Route path="/CusmoterCountPageEdit" element={<CusmoterCountPageEdit />} />
                    <Route path="/ConfigAssignAdd" element={<ConfigAssignAdd />} />
                    <Route path="/AssignWebphoneConfigList" element={<AssignWebphoneConfigList />} />
                    <Route path="/AssignWebphoneConfigAdd" element={<AssignWebphoneConfigAdd />} />
                    <Route path="/AssignWebphoneConfigEdit" element={<AssignWebphoneConfigEdit />} />
                    <Route path="/AssignWebphoneConfigView" element={<AssignWebphoneConfigView />} />
                    <Route path="/CompanyList" element={<CompanyList />} />
                    <Route path="/CompanyAdd" element={<CompanyAdd />} />
                    <Route path="/CompanyEdit" element={<CompanyEdit />} />
                    <Route path="/CompanyView" element={<CompanyView />} />
                    <Route path="/Uploadxl" element={<Uploadxl />} />
                    <Route path="/Recycle" element={<Recycle />} />

                    <Route path="/ListName" element={<ListName />} />
                    <Route path="/ListAdd" element={<ListAdd />} />
                    <Route path="/ListEdit" element={<ListEdit />} />
                    <Route path="/ListView" element={<ListView />} />
                    
                    <Route path="/AssignList" element={<AssignList />} />
                    <Route path="/AssignAdd" element={<AssignAdd />} />
                    <Route path="/AssignEdit" element={<AssignEdit />} />
                    <Route path="/AssignView" element={<AssignView />} />
                </Route>
                <Route path="/" element={<Login />} />
           </Routes>
        </Router>


    );
}

export default AppRouter;