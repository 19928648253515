import React, { useState, useEffect } from 'react';
import API from '../../Services/BaseService';
// import { useSelector } from 'react-redux';
import CommonPopup from '../../Component/CommonPopup/CommonPopup';
// import Loading from '../../Component/Loading/Loading';
import checkImg from '../../../src/assets/img/check.png';
import errorImg from '../../../src/assets/img/uncheck.png';

const Reassign = (props) => {
    console.log("props", props);

    // const userData = useSelector(state => state.LoginReducer.payload);

    const [toggleZipcodePopupOpen, setToggleZipcodePopupOpen] = useState(true);
    const [alertimg, setAlertImg] = useState("");

    const [popupTitle, /* setPopupTitle */] = useState("");
    const [popupMsg, /* setPopupMsg */] = useState("");
    const [popupType, /* setPopupType */] = useState("");
    const [popupActionType, /* setPopupActionType */] = useState("");
    const [popupActionValue, /* setPopupActionValue */] = useState("");
    const [CompanyName, setCompanyName] = useState("");
    const [selectedCustomerName, setSelectedCustomerName] = useState("");
    const [CompanyNameError, setCompanyNameError] = useState("");
    const [OperatorName, setOperatorName] = useState("");
    const [selectedOperatorName, setselectedOperatorName] = useState("");
    const [/* OperatorId */, setOperatorId] = useState("");

    const [ListName, setListName] = useState("");
    const [SelectedListName, setSelectedListName] = useState("");
    const [CompanyId, setCompanyId] = useState("");
    const [alertmessage, setAlertMessage] = useState("");
    const [alerttitle, setAlertTitle] = useState("");
    // const [popupActionPath, setPopupActionPath] = useState("");
    const [isOpenPopup, setIsOpenPopup] = useState(false);
    // const [loading, /* setLoading */] = useState(true)
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    useEffect(() => {
        handleCompanyName();
        // handleOperatorName();
        // handleListName();

    }, [])

    const handleCompanyName = () => {

        API.post("companyList/condition").then(response => {
            setCompanyName(response.data.data);
        });
    }
    const handleOperatorName = (data) => {
        let request = {
            company_id: !data ? "" : data.company_id,
        }

        API.post("operatorbycompanyid/condition", request).then(response => {
            setOperatorName(response.data.data);
            //setLoading(false);
        });
    }
    const handleListName = (data) => {
        let request = {
            operator_id: !data ? "" : data.operator_id,
        }
        API.post("customerlistassgin/condition", request).then(response => {
            setListName(response.data.data);
        });
    }
    const handleOperator = (e) => {
        setOperatorId(e.target.value);
        console.log("=========check", e.target.value);
        setselectedOperatorName(
            OperatorName.filter((data) => data.operator_id == e.target.value)[0]
        );
        console.log(
            "=========check123",
            OperatorName.filter((data) => data.operator_id == e.target.value)[0]
        );
        handleListName(OperatorName.filter((data) => data.operator_id == e.target.value)[0]);
    };
    const handleListId = (e) => {
        console.log("=========check", e.target.value);
        setSelectedListName(
            ListName.filter((data) => data.customer_list_id == e.target.value)[0]
        );
        console.log(
            "=========check123",
            ListName.filter((data) => data.customer_list_id == e.target.value)[0]
        );
    };
    const togglePopup = () => {
        setIsOpenPopup(!isOpenPopup);
    };

    const getSelectedReport = () => {
        setPasswordError("")
        setCompanyNameError("")
        if (!CompanyId) {
            setCompanyNameError("Company Name is required");
            return;
        }
        if (props.selectedDontCall > 0 && !password) {
            setPasswordError("Password is required")
            return
        }
        let request = {
            customer_id: props.PageselectAll === false || props.PageselectAll === "" ? props.selectedValues.map((data) => { return { "customer_id": data.customer_id} }): props.FilterSelect,
            password: props.selectedDontCall > 0 ? password : "",
            company_id: selectedCustomerName.company_id ? selectedCustomerName.company_id : "",
            operator_id: selectedOperatorName?.operator_id ? selectedOperatorName?.operator_id : "",
            customer_list_id: SelectedListName?.customer_list_id ? SelectedListName?.customer_list_id : "",
        }
        console.log("selcted", request);

        {
            API.post("customerlistreassign/update", request)
                .then((response) => {
                    setToggleZipcodePopupOpen(false);

                    if (response.data.success == true) {
                        setAlertTitle("Reassign");
                        setAlertMessage("Customers Reassigned Succesfully");
                        setAlertImg(checkImg);
                        props.reCallFunction();

                    } else {
                        setAlertTitle("Reassign");
                        setAlertImg(errorImg);
                        setAlertMessage(response.data.error.message);
                    }
                });

        }
    };

    const handleClose = () => {
        props.toggle();
        props.reCallFunction();
    }
    const handleCompany = (e) => {
        // setCompanyId(e.target.value);
        // const selectedCustomerDetails = CompanyName.filter(data => data.company_id == e.target.value)[0]
        // setSelectedCustomerName(selectedCustomerDetails);
        setCompanyId(e.target.value);
        console.log("=========check", e.target.value);
        setSelectedCustomerName(
            CompanyName.filter((data) => data.company_id == e.target.value)[0]
        );
        console.log(
            "=========check123",
            CompanyName.filter((data) => data.company_id == e.target.value)[0]
        );
        handleOperatorName(CompanyName.filter((data) => data.company_id == e.target.value)[0]);
        handleListName(CompanyName.filter((data) => data.company_id == e.target.value)[0]);

    }
    return (

        <div>
            <div>
                {toggleZipcodePopupOpen ?
                    (<>
                        <div className="main-contents">
                            <div className="modal-bodys">
                                {/* {loading ? <Loading /> : */}
                                <div className="row">
                                    <div className="back-heading">
                                        <h5 className="heading-text">Reassign</h5>
                                        <div className='d-flex'>
                                            <div className="col-xl-6  form-group datePickerBlock recycle ">
                                                <label htmlFor="customerName" className="col-form-label">Company Name <span className='mandatory ms-1'>*</span></label>
                                                <select className="form-select" id="customerName" onChange={handleCompany} >
                                                    <option style={{ display: "none" }}>Select CompanyName</option>
                                                    {CompanyName.length > 0 &&
                                                        <>
                                                            {CompanyName.map((data) => <option key={data.company_id} value={data.company_id} >{data.company_name}</option>)}
                                                        </>
                                                    }
                                                </select>
                                                <p className="form-input-error">{CompanyNameError}</p>
                                            </div>



                                            {selectedCustomerName === "" ?
                                                <div className=" col-xl-6  form-group datePickerBlock recycle">
                                                    <label htmlFor="customerName" className="col-form-label">Operator Name </label>
                                                    <select
                                                        className="form-select"
                                                        aria-label="Default select example"
                                                        id="Sale"
                                                        disabled
                                                    >
                                                        <option style={{ display: "none" }}>Select OperatorName</option>
                                                        {/* {OperatorName?.length > 0 && (
                                                 <>
                                                     {OperatorName.map((OperatorNameID) => (
                                                         <option
                                                             key={OperatorNameID.operator_id}
                                                             value={OperatorNameID.operator_id}
                                                         >
                                                             {OperatorNameID.operator_name}
                                                         </option>
                                                     ))}
                                                 </>
                                             )} */}
                                                    </select>
                                                </div>
                                                :
                                                <div className="col-xl-6  form-group datePickerBlock recycle">
                                                    <label htmlFor="customerName" className="col-form-label">Operator Name </label>
                                                    <select
                                                        className="form-select"
                                                        aria-label="Default select example"
                                                        id="Sale"
                                                        onChange={handleOperator}

                                                    >
                                                        <option style={{ display: "none" }}>Select OperatorName</option>
                                                        {OperatorName?.length > 0 && (
                                                            <>
                                                                {OperatorName.map((OperatorNameID) => (
                                                                    <option
                                                                        key={OperatorNameID.operator_id}
                                                                        value={OperatorNameID.operator_id}
                                                                    >
                                                                        {OperatorNameID.operatorname}
                                                                    </option>
                                                                ))}
                                                            </>
                                                        )}
                                                    </select>
                                                </div>
                                            }

                                        </div>
                                        <div className="col-xl-6  form-group datePickerBlock recycle">
                                            <label htmlFor="customerName" className="col-form-label">List Name </label>
                                            <select
                                                className="form-select"
                                                aria-label="Default select example"
                                                id="Sale"
                                                onChange={handleListId}

                                            >
                                                <option style={{ display: "none" }}>Select ListName</option>
                                                {ListName?.length > 0 && (
                                                    <>
                                                        {ListName.map((ListNameID) => (
                                                            <option
                                                                key={ListNameID.customer_list_id}
                                                                value={ListNameID.customer_list_id}
                                                            >
                                                                {ListNameID.list_name}
                                                            </option>
                                                        ))}
                                                    </>
                                                )}
                                            </select>
                                        </div>
                                        <p className='Para-text mt-3'>Don&apos;t call customer count: {`${props.selectedDontCall > 0 ? props.selectedDontCall : 0}`}</p>

                                    </div>
                                </div>
                                {/* } */}
                                {props.selectedDontCall > 0 && <>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <div className=" col-lg-6 me-2 text-center">
                                            <label>Enter Password</label>
                                            <input type="text" className="form-control" value={password} placeholder="" onChange={(e) => setPassword(e.target.value)} />
                                        </div>

                                    </div>
                                    <p className="form-input-error text-center">{passwordError}</p>
                                </>
                                }
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-smloutline-danger me-2" data-bs-dismiss="modal" onClick={handleClose}>Close</button>
                                    <button type="button" className="btn form-submit-btn" onClick={getSelectedReport}>Save </button>
                                </div>
                            </div>
                        </div>
                    </>) :
                    (<div className='uploadpopup'>
                        <div className="Commonfull-icon">
                            <img className={alertimg === checkImg ? "successImg " : "errorImg"} src={alertimg} />
                        </div>
                        <div className="CommonModalbody">
                            <h2>{alerttitle}</h2>
                            <p>{alertmessage}</p>
                        </div>
                        <div className="CommonModalfooter ">
                            <div className="CommonModalfooter session">
                                <button className="btn btn-smlprimary" onClick={props.toggle}>Ok</button>
                            </div>
                        </div>
                    </div>
                    )}
            </div>
            {
                isOpenPopup && (
                    <CommonPopup
                        handleClose={togglePopup}
                        popupTitle={popupTitle}
                        popupMsg={popupMsg}
                        popupType={popupType}
                        popupActionType={popupActionType}
                        popupActionValue={popupActionValue}
                    // popupActionPath={popupActionPath}
                    />
                )
            }
        </div>



    );
};

export default Reassign