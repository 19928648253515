import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
// import { useSelector } from 'react-redux';
import Loading from '../../Component/Loading/Loading';
//import CommonPopup from '../../Component/CommonPopup/CommonPopup';
import PhoneInputs from 'react-phone-input-2';
import ls from 'local-storage';

const CustomerView = () => {
    const navigate = useNavigate();
    // const userData = useSelector(state => state.LoginReducer.payload);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [pNumber, setPNumber] = useState("");
    const [comment, setComment] = useState("");
    const [Vehicle, setVehicle] = useState("");
    const [Stock, setStock] = useState("");
    const [Color, setColor] = useState("");
    const [Source, setSource] = useState("");
    const [status, setStatus] = useState("");
    const [CompanyName, setCompanyName] = useState("");
    const [ListName, setListName] = useState("");
    const [operator,setOperator] = useState("");
    // const [firstNameError, setFirstNameError] = useState("");
    // const [lastNameError, setLastNameError] = useState("");
    //const [emailError, setEmailError] = useState("");
   // const [pNumberError, setPNumberError] = useState("");
    //const [commentError, setCommentError] = useState("");
    // const [ StatusError , setStatusError] = useState("");
    const [loading, setLoading] = useState(true)
    //const location = useLocation()
   // const CustomerId = location.state.CustomerId;
    const CustomerId = ls.get("AdminId");

   
   

    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };

    useEffect(() => {
        Operatorview();
    }, [])

    const Operatorview = () => {
        let request = {
            customer_id: CustomerId
        };
        API.post('customerview/condition ', request).then((response) => {
            if (response.data.success == true) {
                setFirstName(response.data.data[0]?.first_name);
                setLastName(response.data.data[0]?.last_name);
                setEmail(response.data.data[0]?.email);
                setPNumber(response.data.data[0]?.Phone);
                setComment(response.data.data[0]?.CommentsByAdmin);
                setStatus(response.data.data[0]?.status);
                setVehicle(response.data.data[0]?.vehicle);
                setStock(response.data.data[0]?.stock);
                setColor(response.data.data[0]?.color);
                setSource(response.data.data[0]?.source);
                //setCompanyName(response.data.data[0]?.company_name);

                const companynames = [...new Set(response.data.data.map(item => item.company_name))].join(', '); 
                    setCompanyName(companynames);


                    const names = [...new Set(response.data.data.map(item => item.list_name))].join(', '); 
                    setListName(names);
    


                    const operatornames = [...new Set(response.data.data.map(item => item.operatorname))].join(', '); 
                    setOperator(operatornames);
                


                //setListName(response.data.data[0]?.list_name);
                setLoading(false);
            }
        });
    }

    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">

                    <div className="row">

                        <div className="back-heading">
                            <div className="back-btn" onClick={() => navigate('/customers')}><i className="fa-sharp fa-solid fa-arrow-left me-1"></i>Back</div>
                            <h5 className="heading-text">View Customer</h5>
                        </div>
                        <div className="rounded table-responsive">
                            <div className="modal-body">
                                {loading ? <Loading /> :
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-4 ">
                                                <label htmlFor="first-name" className="col-form-label">First Name </label>
                                                <input type="text" className="form-control" id="first-name" value={firstName} onChange={(e) => setFirstName(e.target.value)} disabled/>
                                              
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="last-name" className="col-form-label">Last Name </label>
                                                <input type="text" className="form-control" id="last-name" value={lastName} onChange={(e) => setLastName(e.target.value)} disabled />
                                               
                                            </div>

                                            <div className="col-lg-4">
                                                <label htmlFor="email" className="col-form-label">Email </label>
                                                <input type="text" className="form-control" id="email" value={email === null || email === "null" ? "" :email} onChange={(e) => setEmail(e.target.value)} disabled />
                                                
                                            </div>

                                            <div className="col-lg-4">
                                                {/* <label htmlFor="phone-number" className="col-form-label">Phone Number <span className='mandatory ms-1'>*</span></label>
                                                <input type="text" className="form-control" id="phone-number" value={pNumber} onChange={(e) => setPNumber(e.target.value)} /> */}
                                                 <label htmlFor="phone-number" className="col-form-label"> Phone</label>
                                                <PhoneInputs
                                                class="form-control mt-5"
                                                country={'us'}
                                                disableDropdown={true}
                                                disableCountryCode={true}
                                                onlyCountries={['us']}
                                                value={pNumber}
                                                onChange={(e) => setPNumber(e)}
                                                placeholder=""
                                                disabled
                                            ></PhoneInputs>
                                             
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="comment" className="col-form-label">Comments</label>
                                                <input type="text" className="form-control" id="comment" value={comment === null || comment === "null" ? "" :comment} onChange={(e) => setComment(e.target.value)} disabled />
                                                {/* <p className="form-input-error">{commentError}</p> */}
                                            </div>
                    
                                        <div className="col-lg-4">
                                                <label htmlFor="status" className="col-form-label">Status </label>
                                                <input type="text" className="form-control" id="status" value={status == 1 || status == "Active"? "Active" :"Inactive"} onChange={(e) => setStatus(e.target.value)} disabled/>
                                            </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="Vehicle" className="col-form-label">Vehicle</label>
                                            <input type="text" className="form-control" id="Vehicle" value={Vehicle === null || Vehicle === "null" ? "" :Vehicle} onChange={(e) => setVehicle(e.target.value)} disabled />
                                        </div>
                                        <div className="col-lg-4"> 
                                            <label htmlFor="Stock" className="col-form-label">Stock {/* <span className='mandatory ms-1'>*</span> */}</label>
                                            <input type="text" className="form-control" id="Stock" value={Stock === null || Stock === "null" ? "" :Stock} onChange={(e) => setStock(e.target.value)} disabled />
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="Color" className="col-form-label">Color {/* <span className='mandatory ms-1'>*</span> */}</label>
                                            <input type="text" className="form-control" id="Color" value={Color === null || Color === "null" ? "" :Color} onChange={(e) => setColor(e.target.value)} disabled />
                                        </div>
                                        <div className="col-lg-4">
                                            <label htmlFor="Source" className="col-form-label">Source {/* <span className='mandatory ms-1'>*</span> */}</label>
                                            <input type="text" className="form-control" id="Source" value={Source === null || Source === "null" ? "" :Source} onChange={(e) => setSource(e.target.value)} disabled />
                                        </div>
                                        <div className="col-lg-4">
                                                <label htmlFor="username" className="col-form-label">Company Name </label>
                                                <input type="text" className="form-control" id="username" value={CompanyName}  disabled />
                                                
                                            </div>
                                            <div className="col-lg-4">
                                                <label htmlFor="listname" className="col-form-label">List Name </label>
                                                <input type="text" className="form-control" id="listname" value={ListName}  disabled />
                                                
                                            </div>

                                            
                                          <div className="col-lg-4">
                                                <label htmlFor="listname" className="col-form-label">Operator Name </label>
                                                <input type="text" className="form-control"  value={operator}  disabled />
                                                
                                            </div>



                                        </div>
                                    </form>}
                                <div className="modal-footer">
                                    <button type="button" className="btn form-close-btn"  onClick={() => navigate('/customers')}>Close</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* {isOpenPopup && (
                <CommonPopup
                    handleClose={togglePopup}
                    popupTitle={popupTitle}
                    popupMsg={popupMsg}
                    popupType={popupType}
                    popupActionType={popupActionType}
                    popupActionValue={popupActionValue}
                    popupActionPath={popupActionPath}
                />
            )} */}
        </div>
    );
};
export default CustomerView;